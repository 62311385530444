import React, { useEffect, useRef, useState } from 'react'
import SideBar from '../../../Components/Utilisateur/SideBar'
import Header from '../../../Components/Utilisateur/Header'
import {LeftCircleOutlined} from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import html2pdf from 'html2pdf.js';
import { api } from '../../../api'
import axios from 'axios'
import { Empty } from 'antd';

const RapportFinancier = () => {

  const exportRef = useRef(); // Reference for the div to export
  const {mois, annee} = useParams()
  const user_id = sessionStorage.getItem('admin_id');

  // Récuperer le nom du mois
  const moisNom = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  const moisIndex = parseInt(mois) - 1;
  const nomMois = moisNom[moisIndex] || "Mois inconnu";

  const [loading, setLoading] = useState(true);
  const [dataExist, setDataExist] = useState(false);
  const [tresorerie, setTresorerie] = useState(0);
  const [tiers, setTiers] = useState(0);
  const [tresorerieFinMois, setTresorerieFinMois] = useState(0);
  const [tiersFinMois, setTiersFinMois] = useState(0);
  const [debut21, setDebut21] = useState(0); const [debut60, setDebut60] = useState(0); const [debut61, setDebut61] = useState(0);
  const [debut62, setDebut62] = useState(0); const [debut63, setDebut63] = useState(0); const [debut64, setDebut64] = useState(0);
  const [debut65, setDebut65] = useState(0); const [debut66, setDebut66] = useState(0);
  const [totalDebut21, setTotalDebut21] = useState(0); const [totalDebut60, setTotalDebut60] = useState(0); const [totalDebut61, setTotalDebut61] = useState(0);
  const [totalDebut62, setTotalDebut62] = useState(0); const [totalDebut63, setTotalDebut63] = useState(0); const [totalDebut64, setTotalDebut64] = useState(0); const [totalDebut65, setTotalDebut65] = useState(0); const [totalDebut66, setTotalDebut66] = useState(0);
  const [debut70, setDebut70] = useState(0); const [debut10, setDebut10] = useState(0); const [totalDebut70, setTotalDebut70] = useState(0);
  const [group21, setGroup21] = useState(0); const [group60, setGroup60] = useState(0); const [group61, setGroup61] = useState(0); const [group62, setGroup62] = useState(0);
  const [group63, setGroup63] = useState(0); const [group64, setGroup64] = useState(0); const [group65, setGroup65] = useState(0); const [group66, setGroup66] = useState(0)
  const navigate = useNavigate()

  const revenir = () => {
    navigate('/utilisateur/anneeFiscale/'+ annee + '/' + mois)
  }

  const handleExportPDF = () => {
    const element = exportRef.current;
    html2pdf()
      .from(element)
      .set({
        margin: 0,
        padding: 2,
        filename: 'rapport_financier.pdf',
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'portrait' }
      })
      .save();
  };

  // Récuperer les informations dans le rapport financier
  const getDataRapportFinancier = async () => {
    try {
      const response = await axios.get(api + `/api/getRapportFinancier/${mois}/${annee}/${user_id}`);

      setLoading(false)

      if (response.data.existe)
      {
        const tresorerieValue = parseFloat(response.data.tresorerie);
        const tiersValue = parseFloat(response.data.tiers); const debut21Value = parseFloat(response.data.debut21); const debut60Value = parseFloat(response.data.debut60);
        const debut61Value = parseFloat(response.data.debut61); const debut62Value = parseFloat(response.data.debut62); const debut63Value = parseFloat(response.data.debut63);
        const debut64Value = parseFloat(response.data.debut64); const debut65Value = parseFloat(response.data.debut65); const debut66Value = parseFloat(response.data.debut66);
        const tresorerieFinMoisValue = parseFloat(response.data.tresorerieFinMois);
        const tiersFinMoisValue = parseFloat(response.data.tiersFinMois);
        // Cumul des depenses
        const totalDebut21Value = parseFloat(response.data.totalDebut21); const totalDebut60Value = parseFloat(response.data.totalDebut60); const totalDebut61Value = parseFloat(response.data.totalDebut61);
        const totalDebut62Value = parseFloat(response.data.totalDebut62); const totalDebut63Value = parseFloat(response.data.totalDebut63); const totalDebut64Value = parseFloat(response.data.totalDebut64);
        const totalDebut65Value = parseFloat(response.data.totalDebut65); const totalDebut66Value = parseFloat(response.data.totalDebut66);

        if (response.data.budgetAnnuel.length === 0)
        {
          const group21Value = 0; const group60Value = 0; const group61Value = 0; const group62Value = 0;
          const group63Value = 0; const group64Value = 0; const group65Value = 0; const group66Value = 0;
          setGroup21(group21Value); setGroup60(group60Value); setGroup61(group61Value); setGroup62(group62Value);
          setGroup63(group63Value); setGroup64(group64Value); setGroup65(group65Value); setGroup66(group66Value);
        } else {
          const group21Value = parseFloat(response.data.budgetAnnuel[0].group21); const group60Value = parseFloat(response.data.budgetAnnuel[0].group60);
          const group61Value = parseFloat(response.data.budgetAnnuel[0].group61); const group62Value = parseFloat(response.data.budgetAnnuel[0].group62);
          const group63Value = parseFloat(response.data.budgetAnnuel[0].group63); const group64Value = parseFloat(response.data.budgetAnnuel[0].group64);
          const group65Value = parseFloat(response.data.budgetAnnuel[0].group65); const group66Value = parseFloat(response.data.budgetAnnuel[0].group66);
          setGroup21(group21Value); setGroup60(group60Value); setGroup61(group61Value); setGroup62(group62Value);
          setGroup63(group63Value); setGroup64(group64Value); setGroup65(group65Value); setGroup66(group66Value);
        }

        const debut70Value = parseFloat(response.data.debut70); const debut10Value = parseFloat(response.data.debut10); const totalDebut70Value = parseFloat(response.data.totalDebut70);
        setTresorerie(tresorerieValue);
        setTiers(tiersValue);
        setDebut21(debut21Value);
        setDebut60(debut60Value); setDebut61(debut61Value); setDebut62(debut62Value); setDebut63(debut63Value); setDebut64(debut64Value);
        setDebut65(debut65Value); setDebut66(debut66Value);
        // Cumul des depenses
        setTotalDebut21(totalDebut21Value);
        setTotalDebut60(totalDebut60Value); setTotalDebut61(totalDebut61Value); setTotalDebut62(totalDebut62Value); setTotalDebut63(totalDebut63Value); setTotalDebut64(totalDebut64Value);
        setTotalDebut65(totalDebut65Value); setTotalDebut66(totalDebut66Value);

        setDebut70(debut70Value); setDebut10(debut10Value); setTotalDebut70(totalDebut70Value);

        setTresorerieFinMois(tresorerieFinMoisValue); setTiersFinMois(tiersFinMoisValue);

      } else {
        setDataExist(true)
      }

      console.log(response.data);
      // setLoading(false);

    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };
  
  useEffect(() => {
    getDataRapportFinancier();
  }, []);

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div><Header titre={'Rapport financier'}/></div>

        <div className='py-6'>
          <div className='flex justify-between'>
              <div className='ml-8'>
                <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
              </div>
              <div className='pr-7'>
                {
                  !dataExist && (
                    <>

                      <button
                        onClick={handleExportPDF}
                        className='bg-blue-500 text-white py-1 px-3 rounded'
                      >
                        Exporter en PDF
                      </button>

                    </>
                  )
                }
              </div>
            </div>

            {
              loading ? (
                <>
                  <div className='justify-center items-center flex mt-28'>
                    <div className="w-10 h-10 justify-center items-center border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                  </div>
                </>
              ) : (
                <>

                  {
                    dataExist ? (
                      <div className='flex flex-col items-center justify-center h-full p-2 mt-44'>
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span className='text-lg'>Vous n'avez pas encore des données. <br /></span>}
                          />
                      </div>
                    ) : (
                      <>
                      
                        {/* Contenu à exporter en PDF */}
                        <div ref={exportRef} className='pl-8 pr-7 py-6'>
                          <div className='text-center text-lg mb-4 font-bold'>
                            <span>RAPPORT FINANCIER MENSUEL</span>
                          </div>
                          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-20'>
                            <div className='grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 gap-3'>
                              <div className='flex flex-col font-semibold space-y-1'>
                                <div className='space-x-7'><span>CLIENT :</span><span>EXPERT CONSEILS</span></div>
                                <div className='space-x-9'><span>CODE :</span><span>TNR-ACEP180006</span></div>
                                {/* <span className='pt-1'>SOLDE AU DEBUT DU MOIS :</span>
                                <span className='text-right pr-12'>Trésorerie :</span>
                                <span className='text-right pr-12'>Tiers :</span>
                                <span className='text-right pr-12 pt-1'>RECETTES DE LA PERIODE :</span>
                                <span className='text-right pr-12'>Ventes :</span>
                                <span className='text-right pr-12'>Revenu :</span>
                                <span className='text-right pr-12 pt-2'>DEPENSES DU MOIS :</span> */}
                              </div>

                            </div>

                            <div className='grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 gap-3 font-semibold'>
                            <div className='flex flex-col font-semibold space-y-1'>
                                <div className='space-x-9'><span>RAPPORT MOIS DE :</span><span>{nomMois}</span></div>
                                <div className='space-x-16'><span>ANNEE FISCALE :</span><span>{annee}</span></div>
                                {/* <span className='p pt-28'>CUMUL RECETTES :</span> */}
                              </div>

                              {/*  */}
                            </div>
                          </div>

                          <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 mt-6'>
                            <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3'>
                                <div className='flex flex-col font-semibold space-y-1'>
                                  {/* <span>CLIENT :</span> */}
                                  {/* <span>CODE :</span> */}
                                  <span className=''>SOLDE AU DEBUT DU MOIS :  <span className='ml-8'>{(tresorerie + tiers).toLocaleString('fr-FR')}</span></span>
                                  <span className='p pl-32'>Trésorerie :  <span className='ml-8'>{tresorerie.toLocaleString('fr-FR')}</span></span>
                                  <span className='p pl-[165px]'>Tiers : <span className='ml-8'>{tiers.toLocaleString('fr-FR')}</span></span>
                                  <span className='pt-3 pl-[53px]'>DEPENSES DU MOIS : <span className='ml-8'>{(debut60 + debut61 + debut62 + debut63 + debut64 + debut65 + debut66).toLocaleString('fr-FR')}</span></span> 
                                  
                                </div>

                                <div className='flex flex-col font-semibold space-y-1'>
                                  
                                  {/* Solde au début du mois */}
                                  <div className=''>
                                    <span className='pt-1'>RECETTES DE LA PERIODE <span className='ml-8' style={{ color: '#F9F9FC' }}>34 567 677</span></span>
                                  </div>
                                  {/* vente */}
                                  <div className=''>
                                    <span className='pl-[132px]'>Ventes : <span className='ml-8'>{debut70.toLocaleString('fr-FR')}</span></span>
                                  </div>
                                  {/* Revenu */}
                                  <div className=''>
                                    <span className='pl-32'>Revenu : <span className='ml-8'>{debut10.toLocaleString('fr-FR')}</span></span>
                                  </div>
                                  <div className='pt-3'>
                                    <span className=' pl-[52px]'>CUMUL RECETTES : <span className='ml-8'>{totalDebut70.toLocaleString('fr-FR')}</span></span> 
                                  </div>
                                </div>
                              </div>
                          </div>

                          {/* Tableau */}
                          <div className="overflow-x-auto mt-6">
                            <table className="min-w-full border-collapse bg-white border">
                              <thead>
                                <tr className="border-b bg-primary text-white">
                                  <th className="py-2 px-2 text-center">DEPENSES ET COMPARAISON BUDGETAIRE</th>
                                  <th className="py-2 px-2 text-center">BUDGET ANNUEL</th>
                                  <th className="py-2 px-2 text-center">DEPENSES DU MOIS</th>
                                  <th className="py-2 px-2 text-center">CUMUL DES DEPENSES</th>
                                  <th className="py-2 px-2 text-center">BUDGET ANNUEL %</th>
                                  <th className="py-2 px-2 text-center">BALANCE</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className='bg-gray-200'>
                                  <td className='py-2 px-1 border-black'>Fampitaovana maharitra</td>
                                  <td className='py-2 px-1 text-right border-black'>{group21.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{debut21.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{totalDebut21.toLocaleString('fr-FR')}</td>
                                  {
                                    group21 == 0 ? (
                                      <><td className='py-2 px-1 text-right border-black'>#DIV/0!</td> </>
                                    ) : (
                                      <><td className='py-2 px-1 text-right border-black'>{(totalDebut21/group21).toFixed(2)} % </td></>
                                    )
                                  }                                  
                                  <td className='py-2 px-1 text-right border-black'>{(group21 - totalDebut21).toLocaleString('fr-FR')}</td>
                                </tr>
                                <tr className='bg-blue-200'>
                                  <td className='py-2 px-1 border-black'>Fandaniana mivantana momba ny varotra</td>
                                  <td className='py-2 px-1 text-right border-black'>{group60.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{debut60.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black whitespace-nowrap'>{totalDebut60.toLocaleString('fr-FR')}</td>
                                  {
                                    group60 == 0 ? (
                                      <><td className='py-2 px-1 text-right border-black'>#DIV/0!</td> </>
                                    ) : (
                                      <><td className='py-2 px-1 text-right border-black'>{(totalDebut60/group60).toFixed(2)} %</td></>
                                    )
                                  }
                                  <td className='py-2 px-1 text-right border-black'>{(group60 - totalDebut60).toLocaleString('fr-FR')}</td>
                                </tr>
                                <tr className='bg-gray-200'>
                                  <td className='py-2 px-1 border-black'>Fandaniana hafa ilaina mikasika ny varotra</td>
                                  <td className='py-2 px-1 text-right border-black'>{group61.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{debut61.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{totalDebut61.toLocaleString('fr-FR')}</td>
                                  {
                                    group61 == 0 ? (
                                      <><td className='py-2 px-1 text-right border-black'>#DIV/0!</td> </>
                                    ) : (
                                      <><td className='py-2 px-1 text-right border-black'>{(totalDebut61/group61).toFixed(2)} %</td></>
                                    )
                                  }
                                  <td className='py-2 px-1 text-right border-black'>{(group61 - totalDebut61).toLocaleString('fr-FR')}</td>
                                </tr>
                                <tr className='bg-blue-200'>
                                  <td className='py-2 px-1 border-black'>Fandaniana hafa ilaina nataon'ny olon-kafa</td>
                                  <td className='py-2 px-1 text-right border-black'>{group62.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{debut62.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{totalDebut62.toLocaleString('fr-FR')}</td>
                                  {
                                    group62 == 0 ? (
                                      <><td className='py-2 px-1 text-right border-black'>#DIV/0!</td> </>
                                    ) : (
                                      <><td className='py-2 px-1 text-right border-black'>{(totalDebut62/group62).toFixed(2)} %</td></>
                                    )
                                  }
                                  <td className='py-2 px-1 text-right border-black'>{(group62 - totalDebut62).toLocaleString('fr-FR')}</td>
                                </tr>
                                <tr className='bg-gray-200'>
                                  <td className='py-2 px-1 border-black'>Hetra</td>
                                  <td className='py-2 px-1 text-right border-black'>{group63.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{debut63.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{totalDebut63.toLocaleString('fr-FR')}</td>
                                  {
                                    group63 == 0 ? (
                                      <><td className='py-2 px-1 text-right border-black'>#DIV/0!</td> </>
                                    ) : (
                                      <><td className='py-2 px-1 text-right border-black'>{(totalDebut63/group63).toFixed(2)} %</td></>
                                    )
                                  }
                                  <td className='py-2 px-1 text-right border-black'>{(group63 - totalDebut63).toLocaleString('fr-FR')}</td>
                                </tr>
                                <tr className='bg-blue-200'>
                                  <td className='py-2 px-1 border-black'>Karaman'ny mpiasa</td>
                                  <td className='py-2 px-1 text-right border-black'>{group64.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{debut64.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{totalDebut64.toLocaleString('fr-FR')}</td>
                                  {
                                    group64 == 0 ? (
                                      <><td className='py-2 px-1 text-right border-black'>#DIV/0!</td> </>
                                    ) : (
                                      <><td className='py-2 px-1 text-right border-black'>{(totalDebut64/group64).toFixed(2)} %</td></>
                                    )
                                  }
                                  <td className='py-2 px-1 text-right border-black'>{(group64 - totalDebut64).toLocaleString('fr-FR')}</td>
                                </tr>
                                <tr className='bg-gray-200'>
                                  <td className='py-2 px-1 border-black'>Fandaniana hafa</td>
                                  <td className='py-2 px-1 text-right border-black'>{group65.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{debut65.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{totalDebut65.toLocaleString('fr-FR')}</td>
                                  {
                                    group65 == 0 ? (
                                      <><td className='py-2 px-1 text-right border-black'>#DIV/0!</td> </>
                                    ) : (
                                      <><td className='py-2 px-1 text-right border-black'>{(totalDebut65/group65).toFixed(2)} %</td></>
                                    )
                                  }
                                  <td className='py-2 px-1 text-right border-black'>{(group65 - totalDebut65).toLocaleString('fr-FR')}</td>
                                </tr>
                                <tr className='bg-blue-200'>
                                  <td className='py-2 px-1 border-black'>Charges financières</td>
                                  <td className='py-2 px-1 text-right border-black'>{group66.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{debut66.toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{totalDebut66.toLocaleString('fr-FR')}</td>
                                  {
                                    group66 == 0 ? (
                                      <><td className='py-2 px-1 text-right border-black'>#DIV/0!</td> </>
                                    ) : (
                                      <><td className='py-2 px-1 text-right border-black'>{(totalDebut66/group66).toFixed(2)} %</td></>
                                    )
                                  }
                                  <td className='py-2 px-1 text-right border-black'>{(group66 - totalDebut66).toLocaleString('fr-FR')}</td>
                                </tr>
                                <tr className='bg-primary text-white font-bold'>
                                  <td className='py-2 px-1 border-black text-center font-bold'>TOTAL</td>
                                  <td className='py-2 px-1 border-black text-right font-bold'>{(group21 + group60 + group61 + group62 + group63 + group64 + group65 + group66).toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{(debut60 + debut61 + debut62 + debut63 + debut64 + debut65 + debut66).toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>{(totalDebut21 + totalDebut60 + totalDebut61 + totalDebut62 + totalDebut63 + totalDebut64 + totalDebut65 + totalDebut66).toLocaleString('fr-FR')}</td>
                                  <td className='py-2 px-1 text-right border-black'>
                                    {
                                    (group21 + group60 + group61 + group62 + group63 + group64 + group65 + group66) == 0 ? (
                                      0
                                    ) : 
                                    (
                                      (totalDebut21 + totalDebut60 + totalDebut61 + totalDebut62 + totalDebut63 + totalDebut64 + totalDebut65 + totalDebut66) /
                                      (group21 + group60 + group61 + group62 + group63 + group64 + group65 + group66)
                                    ).toFixed(2)} %
                                  </td>                      
                                  <td className='py-2 px-1 text-right border-black'>{((0 - totalDebut21) + (0 - totalDebut60) + (0 - totalDebut61) + (0 - totalDebut62) + (0 - totalDebut63) + (0 - totalDebut64) + (0 - totalDebut65) + (0 - totalDebut66)).toLocaleString('fr-FR')}</td>
                                </tr>
                                
                              </tbody>
                            </table>
                          </div>

                          <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 mt-10'>
                            <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3'>
                                <div className='flex flex-col font-semibold space-y-1'>
                                  {/* <span>CLIENT :</span> */}
                                  {/* <span>CODE :</span> */}
                                  <span className=''>SOLDE A LA FIN DU MOIS : <span className='ml-10'>{((tresorerie + tiers) + (debut70) + (debut10) - (debut21 + debut60 + debut61 + debut62 + debut63 + debut64 + debut65 + debut66)).toLocaleString('fr-FR')}</span></span>
                                  <span className='pl-[117px]'>Trésorerie : <span className='ml-10'>{tresorerieFinMois.toLocaleString('fr-FR')}</span></span>
                                  <span className='pl-[153px]'>Tiers : <span className='ml-10'>{tiersFinMois.toLocaleString('fr-FR')}</span></span>
                                  
                                </div>      

                                <div className='flex flex-col font-semibold'>
                                  {/* <span>CLIENT :</span> */}
                                  {/* <span>CODE :</span> */}
                                  
                                  <span className='pl-1'>RESULTATS DE LA PERIODE : <span className='ml-10'>{(debut70 - (debut60 + debut61 + debut62 + debut63 + debut64 + debut65 + debut66)).toLocaleString('fr-FR')}</span></span>
                                  <span className='pt-1 pl-[61px]'>RESULTAT CUMULE : <span className='ml ml-10'>{(totalDebut70 - (totalDebut60 + totalDebut61 + totalDebut62 + totalDebut63 + totalDebut64 + totalDebut65 + totalDebut66)).toLocaleString('fr-FR')}</span></span>
                                </div>           
                            </div>

                          </div>

                          
                        </div>

                      </>
                    )
                  }

                </>
              )
            }

        </div>

      </div>
      
    </div>
  )
}

export default RapportFinancier







	
