import React from 'react'
import NavBar from '../../Components/NavBar'
import Home from '../../Components/Home'
import Apropos from '../../Components/Apropos'
import Fonctionnalite from '../../Components/Fonctionnalite'
import About from '../../Components/About'
import Abonnement from '../../Components/Abonnement'
import Partenaire from '../../Components/Partenaire'
import Footer from '../../Components/Footer'

function Accueil() {
  return (
    <div>
      <NavBar/>
      <Home />
      <Apropos />
      <Fonctionnalite />
      <About />
      <Abonnement />
      <Partenaire />
      <Footer />
    </div>
  )
}

export default Accueil