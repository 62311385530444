import React from 'react'

const Apropos = () => {
  return (
    <>
      <div className='md:text-center md:px-24 max-sm:px-4'>
        <h1 className='text-2xl font-bold mb-6 max-sm:text-center max-sm:mt-5'>A propos</h1>
        <p className='t text-lg'>Notre application de gestion de comptabilité de base a été développée par une équipe de professionnels
        passionnés par l’innovation financière. Notre mission à cette application est de simplifier la gestion 
        financière pour les petites et moyennes entreprises en offrant un outil de comptabilité intuitif et 
        accessible. Nous croyons que chaque entreprise mérite d’avoir une vue claire et organisée de ses finances,
        sans complexité inutile. </p>
      </div>
    </>
  )
}

export default Apropos