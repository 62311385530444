import React, { useEffect, useState } from 'react'
import SideBar from '../../../Components/SuperAdmin/SideBar'
import Header from '../../../Components/SuperAdmin/Header'
import { DownOutlined,EllipsisOutlined, FilterOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, message as antdMessage } from 'antd';
import { Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import {Modal} from 'antd'
import axios from 'axios';
import { api } from '../../../api';

const Abonnement = () => {

  const [messageApi, contextHolder] = antdMessage.useMessage();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { confirm } = Modal;
  const successAcceptation = () => {
    messageApi.open({
      type: 'success',
      content: 'Demande d\'abonnement acceptée avec succès !',
    });
  };

  const successRefus = () => {
    messageApi.open({
      type: 'success',
      content: 'Demande d\'abonnement refusée avec succès !',
    });
  };

  const navigate = useNavigate()

  // Fonction pour rediriger vers la page des détails
  const  detailDemande = (id) => {
    navigate(`/superAdmin/demande/${id}`);
  };

  // Fonction pour rediriger vers la page des détails
  // const detailUtilisateur = (id) => {
  //   navigate(`/superAdmin/utilisateur/${id}`);
  // };

  const [objet, setObjet] = useState('')
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('luciano@gmail.com')

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  // Fonction pour rediriger vers la page des détails
  const infoUser = (id) => {
    navigate(`/superAdmin/utilisateur/${id}`);
  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setOpen(false);
  };

  const items = (id, user_id) => [
    {
      label: <p className='f font-semibold hover:text-primary' onClick={() => detailDemande(id)}>Consulter</p>,
      key: '0',
    },
    {
      label: <p className='f font-semibold hover:text-primary' onClick={() => infoUser(user_id)}>Voir l'utilisateur</p>,
      key: '0',
    },
    // {
    //   label: <p className='f font-semibold hover:text-primary' onClick={() => showConfirmAccepter(id, nomClient, prenomClient)}>Accepter</p>,
    //   key: '1',
    // },
    // {
    //   label: <p className='f font-semibold hover:text-red-600' onClick={() => showConfirmRefuser(id, nomClient, prenomClient)}>Refuser</p>,
    //   key: '2',
    // },
    
  ];

  // const data = [
  //   { id: 1, date: '22 septembre 2024', type: 'standard', prix: '55 000 Ar', etat: 'En attente', nomClient: 'RANDRIANASOLO Assil' },
  //   { id: 2, date: '22 septembre 2024', type: 'standard', prix: '600 000 Ar', etat: 'En attente', nomClient: 'RANDRIANASOLO Assil' },
  //   { id: 3, date: '22 septembre 2024', type: 'standard', prix: '55 000 Ar', etat: 'Accepté', nomClient: 'RANDRIANASOLO Lucio' },
  //   { id: 4, date: '22 septembre 2024', type: 'standard', prix: '600 000 Ar', etat: 'En attente', nomClient: 'RANDRIANASOLO Assil' },
  //   { id: 5, date: '22 septembre 2024', type: 'standard', prix: '55 000 Ar', etat: 'Accepté', nomClient: 'RANDRIANASOLO Assil' },
  //   { id: 6, date: '22 septembre 2024', type: 'standard', prix: '55 000 Ar', etat: 'Refusé', nomClient: 'RANDRIANASOLO Assil' },
  //   { id: 7, date: '22 septembre 2024', type: 'standard', prix: '600 000 Ar', etat: 'En attente', nomClient: 'RANDRIANASOLO Joel' },
  //   { id: 8, date: '22 septembre 2024', type: 'standard', prix: '55 000 Ar', etat: 'En attente', nomClient: 'RANDRIA Assil' },
  //   { id: 9, date: '22 septembre 2024', type: 'standard', prix: '55 000 Ar', etat: 'Refusé', nomClient: 'RANDRIANASOLO Assil' },
  //   { id: 10, date: '22 septembre 2024', type: 'standard', prix: '55 000 Ar', etat: 'En attente', nomClient: 'RANDRIANASOLO Assil' },
  // ];

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);

  // Tous les demandes
  const getAllDemandes = async () => {
    try {
      const response = await axios.get(api + `/api/allDemande`);
      setData(response.data);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Accepter une demande
  const accepterDemande = async (id) => {
    try {
      const response = await axios.post(api + `/api/accepterDemande/${id}`);
      // setData(response.data);
      successAcceptation();
      getAllDemandes()

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Refuser une demande
  const refuserDemande = async (id) => {
    try {
      const response = await axios.post(api + `/api/refuserDemande/${id}`);
      // setData(response.data);
      successRefus();
      getAllDemandes()

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    getAllDemandes();
  }, []);

  // État pour le filtre
  const [filterEtat, setFilterEtat] = useState('');

  // Filtrage des données selon la valeur du filtre
  const filteredData = filterEtat
    ? data.filter(demande => demande.etat === filterEtat)
    : data;

  

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      {contextHolder}
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Demande'}/>
        </div>
        <div className='pl-8 pr-7 py-4'>

          {/* Filtre */}
          <div className='flex space-x-10 mt-3'>
            <div>
              <span className='font-semibold text-lg'>Filtre <span><FilterOutlined /></span> :</span>
            </div>
            {/* <div>
              <input type="date" className='border border-primary rounded-lg w-32 px-1' />
            </div> */}
            {/* Sélecteur de catégorie */}
            <select value={filterEtat} onChange={(e) => setFilterEtat(e.target.value)} className='border px-1 border-primary rounded-md'>
              <option value="">-- Tout afficher --</option>
              <option value="EN ATTENTE">EN ATTENTE</option>
              <option value="ACCEPTEE">ACCEPTEE</option>
              <option value="REFUSEE">REFUSEE</option>
            </select>
            {/* <div>
              <input type="text" placeholder='Rechercher' className='border border-primary rounded-lg w-44 px-2 text-lg' />
            </div> */}
          </div>

            {/* Table */}
        <div className="overflow-x-auto mt-5 rounded-xl">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-300 border-b">
                  <th className="py-2 px-4 text-left">Date</th>
                  <th className="py-2 px-4 text-left">Type</th>
                  <th className="py-2 px-4 text-left">Choix</th>
                  <th className="py-2 px-4 text-left">Prix (Ar)</th>
                  <th className="py-2 px-4 text-left">Etat</th>
                  <th className="py-2 px-4 text-left">Nom d'utilisateur</th>
                  <th className="py-2 px-4 text-left flex justify-center items-center">Actions</th>
                </tr>
              </thead>
              <tbody>

                {
                  loading ? (
                      // Spinner de chargement avec TailwindCSS
                      <>
                      <tr>
                        <td colSpan='7'>
                          <div className="flex justify-center items-center h-32">
                            <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                          </div>
                        </td>
                      </tr>
                      </>
                  ) : (
                      <>
                      {
                        data.length === 0 ? (
                          <tr>
                            <td colSpan="7" className="text-center py-4">
                              <div>Aucune donnée disponible</div>
                            </td>
                          </tr>
                        ) : (
                          filteredData.map((demande) => (
                            <tr key={demande.id} className="border-b">
                              <td className="py-2 px-4">{demande.date}</td>
                              <td className="py-2 px-4">{demande.type}</td>
                              <td className="py-2 px-4">{demande.choix}</td>
                              <td className="py-2 px-4">{(demande.prix).toLocaleString('fr-FR')}</td>    
                              <td className={`py-2 px-4
                                  ${demande.etat === 'EN ATTENTE' ? 'text-green-600' : ''} 
                                  ${demande.etat === 'ACCEPTEE' ? 'text-primary' : ''}
                                  ${demande.etat === 'REFUSEE' ? 'text-red-600' : ''}`}>
                                <div className={`border inline-block px-2 rounded-full ${demande.etat === 'EN ATTENTE' ? 'border-green-600' : ''}
                                ${demande.etat === 'ACCEPTEE' ? 'border-primary' : ''} ${demande.etat === 'REFUSEE' ? 'border-red-600' : ''}`}>
                                  {demande.etat}
                                </div>
                              </td>
                              <td className="py-2 px-4">{demande.user.nom} {demande.user.prenom}</td>
                              <td className="py-2 px-4 space-x-4 flex justify-center items-center">
                                  <Dropdown
                                    menu={{
                                      items: items(demande.id, demande.user?.id),
                                    }}
                                    trigger={['click']}
                                  >
                                    <a
                                      onClick={(e) => e.preventDefault()}
                                      className="text-gray-800 font-medium flex items-center space-x-2 hover:text-blue-600"
                                    >
                                      <Space>
                                        
                                        <EllipsisOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/>
                                      </Space>
                                    </a>
                                  </Dropdown>
                              </td>
                            </tr>
                          ))
                        )
                      }
                      </>
                  )
                }

                
              </tbody>
            </table>
          </div>

        </div>

      </div>

      {/* Modal pour accepter la demande d'abonnement du client */}
      <Modal title="Signaler un client par email" open={open} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel} footer={(
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={handleCancel} 
                  className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button 
                  onClick={handleOk} 
                  className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  disabled={confirmLoading}
                >
                  {confirmLoading ? 'En cours...' : 'Envoyer'}
                </button>
              </div>
            )}
          >

                <div className='space-y-2 mt-4 mb-2'>
                  <label className="font-semibold">Destinataire</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    // autoComplete="email"
                    value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'objet"
                    disabled
                  />
                </div>  

                <div className='space-y-2 mt-4 mb-4'>
                  <label className="font-semibold">Objet</label>
                  <input
                    id="objet"
                    name="objet"
                    type="text"
                    // autoComplete="email"
                    required
                    onChange={(e) => setObjet(e.target.value)}
                    value={objet}
                    // onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'objet"
                  />
                </div>

                <div className='space-y-2 mt-4 mb-8'>
                  <label className="font-semibold">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    type="text"
                    // autoComplete="email"
                    required
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    // onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none relative block w-full px-3 h-24 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez le message"
                  />
                </div>
      </Modal>
      
    </div>
  )
}

export default Abonnement

