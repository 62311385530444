import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { api } from '../../../api';
import axios from 'axios';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const LineChart = ({ mois, annee }) => {
  const [loading, setLoading] = useState(true);
  const user_id = sessionStorage.getItem('user_id');

  const [CA, setCA] = useState(0); const [CA1, setCA1] = useState(0); const [CA2, setCA2] = useState(0); const [CA3, setCA3] = useState(0);
  const [resultat, setResultat] = useState(0); const [resultat1, setResultat1] = useState(0);  const [resultat2, setResultat2] = useState(0);  const [resultat3, setResultat3] = useState(0); 
  const [depense, setDepense] = useState(0); const [depense1, setDepense1] = useState(0); const [depense2, setDepense2] = useState(0); const [depense3, setDepense3] = useState(0);

  // Fonction pour obtenir les noms des mois
  const getMoisLabels = (mois, annee) => {
    const moisNoms = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    const labels = [];

    for (let i = 0; i < 4; i++) {
      const date = new Date(annee, mois - 1 - i, 1);
      labels.unshift(moisNoms[date.getMonth()]);
    }
    return labels;
  };

  // Récupérer les données pour le graphique
  const getDonneeLineChart = async () => {
    try {
      const response = await axios.get(api + `/api/getBilanMois/${mois}/${annee}/${user_id}`);
      
      const CAValue = parseFloat(response.data.CAMoisActuel); const CA1Value = parseFloat(response.data.CAMoisMoins1); const CA2Value = parseFloat(response.data.CAMoisMoins2); const CA3Value = parseFloat(response.data.CAMoisMoins3);
      const resultatValue = parseFloat(response.data.resultatMoisActuel); const resultat1Value = parseFloat(response.data.resultatMoisMoins1); const resultat2Value = parseFloat(response.data.resultatMoisMoins2); const resultat3Value = parseFloat(response.data.resultatMoisMoins3);
      const depenseValue = parseFloat(response.data.depenseMoisActuel); const depense1Value = parseFloat(response.data.depenseMoisMoins1); const depense2Value = parseFloat(response.data.depenseMoisMoins2); const depense3Value = parseFloat(response.data.depenseMoisMoins3); 
      
      setCA(CAValue); setCA1(CA1Value); setCA2(CA2Value); setCA3(CA3Value);
      setResultat(resultatValue); setResultat1(resultat1Value); setResultat2(resultat2Value); setResultat3(resultat3Value);
      setDepense(depenseValue); setDepense1(depense1Value); setDepense2(depense2Value); setDepense3(depense3Value);

      setLoading(false);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };

  useEffect(() => {
    getDonneeLineChart();
  }, [mois, annee]);

  const data = {
    labels: getMoisLabels(mois, annee), // Labels dynamiques des mois
    datasets: [
      {
        label: 'CA',
        data: [CA3, CA2, CA1, CA],
        backgroundColor: 'rgba(77, 219, 255, 0.5)',
        borderColor: 'rgba(77, 219, 255, 1)',
        borderWidth: 2,
        fill: false,
      },
      {
        label: 'Dépenses',
        data: [depense3, depense2, depense1, depense],
        backgroundColor: 'rgba(255, 140, 26, 0.5)',
        borderColor: 'rgba(255, 140, 26, 1)',
        borderWidth: 2,
        fill: false,
      },
      {
        label: 'Résultat',
        data: [resultat3, resultat2, resultat1, resultat],
        backgroundColor: 'rgb(204, 204, 204, 0.5)',
        borderColor: 'rgb(204, 204, 204, 1)',
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: true },
      tooltip: { mode: 'index' },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        title: {
          display: false,
        },
      },
    },
  };

  return loading ? (
    <>
      <div className='justify-center items-center flex mt-28'>
        <div className="w-10 h-10 justify-center items-center border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
      </div>
    </>
  ) : (
    <div className="w-full">
      <Line data={data} options={options} height={300} />
    </div>
  );
};

export default LineChart;
