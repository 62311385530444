import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { api } from '../../../api';
import { Empty } from 'antd';

// Enregistrement des éléments nécessaires dans ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({mois, annee}) => {
  const [loading, setLoading] = useState(true);
  const user_id = sessionStorage.getItem('admin_id');
  const [dataExist, setDataExist] = useState(false);

  const [CA, setCA] = useState(0);
  const [resultat, setResultat] = useState(0);

  const [debut60, setDebut60] = useState(0); const [debut61, setDebut61] = useState(0);
  const [debut62, setDebut62] = useState(0); const [debut63, setDebut63] = useState(0); const [debut64, setDebut64] = useState(0);
  const [debut65, setDebut65] = useState(0); const [debut66, setDebut66] = useState(0);

  // Récuperer les données dans le barChart
  const getDonneeBarChart = async () => {
    try {

        const response = await axios.get(api + `/api/getRapportFinancier/${mois}/${annee}/${user_id}`);
      
        const debut60Value = parseFloat(response.data.debut60);
        const debut61Value = parseFloat(response.data.debut61); const debut62Value = parseFloat(response.data.debut62); const debut63Value = parseFloat(response.data.debut63);
        const debut64Value = parseFloat(response.data.debut64); const debut65Value = parseFloat(response.data.debut65); const debut66Value = parseFloat(response.data.debut66);

        const debut70Value = parseFloat(response.data.debut70);
        setCA(debut70Value)
        setDebut60(debut60Value); setDebut61(debut61Value); setDebut62(debut62Value); setDebut63(debut63Value); setDebut64(debut64Value);
        setDebut65(debut65Value); setDebut66(debut66Value);

        setDataExist(response.data.existe)
        setLoading(false)
        console.log(response.data);

    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };
  
  useEffect(() => {
    getDonneeBarChart();
  }, []);

  // Données du graphique
  const data = {
    labels: ['CA (Vola maty)', 'Résultat (tombom-barotra)'],
    datasets: [
      {
        label: ['CA'],
        data: [CA, CA - (debut60 + debut61 + debut62 + debut63 + debut64 + debut65 + debut66)],
        
        backgroundColor: [
          'rgb(77, 219, 255, 1)',
          'rgb(255, 140, 26,1)'
        ],
        borderColor: [
          'rgb(77, 219, 255, 1)',
          'rgb(255, 140, 26,1)'
        ],
        borderWidth: 1,
      },
    ],
  };

    // <div className="w-full h-96"> 
    //   <Bar data={data} options={options} height={300} />
    // </div>

  // Options de configuration du graphique
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false }, // Désactiver l'affichage des légendes
      // legend: { position: 'top' }, // Légendes en haut du graphique
      // title: { display: true, text: 'Sales by Month' }, // Titre du graphique
    },
    scales: {
      y: { beginAtZero: true }, // L'axe Y commence à 0
    },
  };

  return loading ? (
    <>
      <div className='justify-center items-center flex mt-28'>
        <div className="w-10 h-10 justify-center items-center border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
      </div>
    </>
    ) : (!dataExist ? 
    <div className='flex flex-col items-center justify-center h-full p-2 mt-6'>
    <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={<span className='text-lg'>Vous n'avez pas encore des données. <br /></span>}
    />
    </div>
    : 
    <div className="w-full h-96"> 
       <Bar data={data} options={options} height={300} />
    </div> )
};

export default BarChart;
