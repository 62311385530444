import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import {LeftCircleOutlined, CloseOutlined} from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../api'
import axios from 'axios'
import ModalImage from '../../../Components/ModalImage'

const DetailDemande = () => {

  const navigate = useNavigate()
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour le modal d'image

  const revenir = () => {
    navigate('/admin/demande')
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getInfoDemande = async () => {
    try {
      const response = await axios.get(api + `/api/infoDemande/${id}`);
      setData(response.data[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const [isModalVisibleImg, setIsModalVisibleImg] = useState(false);
  const [imageToShow, setImageToShow] = useState("");

  const handleImageClick = (imageUrl) => {
    setImageToShow(imageUrl);
    setIsModalVisibleImg(true);
  };

  const closeModal = () => {
    setIsModalVisibleImg(false);
    setImageToShow("");
  };

  useEffect(() => {
    getInfoDemande();
  }, []);

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Abonnement'}/>
        </div>
        <div className='pl-8 pr-7 py-6'>
          <div className='j justify-between'>
            <div className='space-x-3'>
              <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
              <span className='text-lg font-semibold'>Détails d'une demande</span>
            </div>
          </div>

          <div className='border-2 mt-8 p-7 rounded-2xl'>

            {
              loading ? (
                <div className="flex justify-center items-center h-32">
                  <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 gap-x-16 py-2">
                
                <div className="py-2 text-left space-y-2">
                  <p className="font-semibold">Message</p>
                  <p>{data.message}</p>
                </div>
                <div className="py-2 text-left  space-y-2">
                  <p className="font-semibold">Objet</p>
                  <p>{data.objet}</p>
                </div>
                <div className="py-2 text-left  space-y-2">
                  <p className="font-semibold">Type</p>
                  <p>{data.type}</p>
                </div>
                <div className="py-2 text-left grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                  <div className=' space-y-2'>
                    <p className="font-semibold">Choix</p>
                    <p>{data.choix}</p>
                  </div>
                  <div className=' space-y-2'>
                    <p className="font-semibold">Prix</p>
                    <p>{parseInt(data.prix).toLocaleString('fr-FR')} Ar</p>
                  </div>
                </div>
                <div className="py-2 text-left  space-y-2">
                  <p className="font-semibold">Date</p>
                  <p>{new Date(data.date).toLocaleDateString('fr-FR', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                              })}</p>
                </div>
                <div className="py-2 text-left  space-y-2">
                  <p className="font-semibold">Etat</p>
                  <p className='font-semibold'>{data.etat}</p>
                </div>
                <div className="py-2 text-left  space-y-2">
                  <p className="font-semibold">Référence de paiement</p>
                  <p>{data.ref}</p>
                </div>
                <div className="py-2 text-left  space-y-2">
                  <p className="font-semibold">Photo du capture d'écran du paiement</p>
                  <button onClick={() => {handleImageClick(data.image)}} className='px-2 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2 hover:text-white hover:bg-primary' >Voir l'image</button>
                </div>
            
            </div>
              )
            }

            

            {/* Des boutons */}
            <div className='flex justify-between mt-8'>
              <div>

              </div>
              {/* <div className='flex space-x-8'>
                <div>
                 <button className='px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2 hover:text-white hover:bg-primary' >Accepter</button>
                </div>
                <div>
                  <button className='px-4 py-1 font-semibold bg-white text-red-500 duration-300 border-red-500 rounded-lg border-2 hover:text-white hover:bg-red-500' >Réfuser</button>
                </div>
              </div> */}
            </div>
          </div>

          

        </div>
      </div>

      {/* Modal pour afficher l'image */}
      {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg w-full">
              <div className='flex justify-between'>
                {/* <h2 className="text-lg font-semibold mb-4">Image du capture d'écran du paiement</h2> */}
                <h2></h2>
                <div>
                  {/* <button
                    onClick={toggleModal}
                    className="px-4 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600"
                  >
                    Fermer
                  </button> */}
                  <CloseOutlined className='border rounded-xl cursor-pointer bg-red-400 text-white hover:bg-red-500 p-2 mb-3' onClick={toggleModal}/>
                </div>
              </div>
              <img src={api + '/storage/' + data.image} alt="Image de la demande" className="w-full h-auto mb-4" />
              
            </div>
          </div>
        )}

        {/* Modal d'affichage */}
      {isModalVisibleImg && (
        <ModalImage
          imageUrl={imageToShow}
          visible={isModalVisibleImg}
          onClose={closeModal}
        />
      )}
      
    </div>
  )
}

export default DetailDemande

