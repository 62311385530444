import React, { useEffect, useState } from 'react'
import SideBar from '../../../Components/Utilisateur/SideBar'
import Header from '../../../Components/Utilisateur/Header'
import axios from 'axios'
import LineChart from '../Charts/LineChartDash'
import ChartCercle from '../../Utilisateur/Charts/ChartCercle'
import BarChart from '../../Utilisateur/Charts/BarChart'
import LineChartSimple from '../../Utilisateur/Charts/LineChart'

const Dashboard = () => {
  const token = sessionStorage.getItem('token');
  const [userInfo, setUserInfo] = useState(null);

  const [nom, setNom] = useState('.....');

  const getCurrentMonth = () => {
    const mois = new Date().getMonth() + 1;
    return mois;
  }

  const getCurrentYear = () => {
    const currentDate = new Date();
    const annee = currentDate.getFullYear()
    return annee;
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get('http://localhost:8000/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserInfo(response.data);
        setNom(response.data.prenom);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, [token]);
  return (
    <div className='bg flex flex-row'>
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div><Header titre={'Bonjour ' + nom}/></div>

        <div className='space-y-56'>
          <div className='mt-1 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 py-4 h-[200px] mx-2'>

            <div className=''>
              <div className='flex text-center justify-center space-x-5'>
                <h1 className='text-center font-semibold'>EVOLUTION CA-DEPENSES-RESULTAT :</h1> 
              </div>
              {/* Affichage du PieChart */}
              <div className="">
                <LineChart annee={getCurrentYear()}/>
              </div>
            </div>

            </div>

            <div className='mt-6 px-5 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-1 py-4'>

              <div className=''>
                <h1 className='text-center font-semibold'>SITUATION DES DEPENSES</h1>
                {/* <h1 className='text-center font-semibold uppercase'>{mois}</h1> */}
                {/* Affichage du PieChart */}
                <div style={{ width: '450px', height: '350px' }}>
                  <ChartCercle mois={getCurrentMonth()} annee={getCurrentYear()}/>
                </div>
              </div>

              <div className=''>
                <h1 className='text-center font-semibold'>RESULTAT DE LA PERIODE PAR RAPPORT AU CA</h1>
                {/* <h1 className='text-center font-semibold uppercase'>{mois}</h1> */}
                {/* Affichage du PieChart */}
                <div className="h-[300px]">
                  <BarChart mois={getCurrentMonth()} annee={getCurrentYear()}/>
                </div>
              </div>

              <div className=''>
                <h1 className='text-center font-semibold'>EVOLUTION CA-DEPENSES-RESULTAT</h1>
                {/* <h1 className='text-center font-semibold uppercase'>{mois}</h1> */}
                {/* Affichage du PieChart */}
                <div className="">
                  <LineChartSimple mois={getCurrentMonth()} annee={getCurrentYear()}/>
                </div>
              </div>

            </div>
        </div>
      </div>
      
    </div>
  )
}

export default Dashboard