import React from 'react'
import img5 from '../Assets/img5.jpg'

const About = () => {
  return (
    <div className='md:px-24 p-4 mx-auto'>
      
      <div className='flex flex-col md:flex-row justify-between items-center gap-10'>
          <div>
            <img src={img5} alt="" className='lg:h-[386px]' />
          </div>

           <div className='md:w-3/5'>
          <h2 className='md:text-lg text-lg'>La comptabilité de base désigne l'ensemble des principes et pratiques essentiels à la gestion financière d'une entreprise ou d'une organisation. 
            Elle permet de suivre les flux financiers (entrées et sorties d'argent) et d'assurer une gestion correcte des comptes. Elle est essentielle pour la gestion financière quotidienne 
            d'une entreprise, même à petite échelle Voici les principales notions de la comptabilité de base: 
            enregistrement des transactions, classement des comptes, le bilan, des rapports financiers et gestion de la trésorerie. <br /> En résumé, la comptabilité de base fournit les outils 
            nécessaires pour suivre la santé financière de l'entreprise, respecter les obligations légales et fiscales, et prendre des décisions économiques rationnelles.</h2>
        </div>
        </div>
        
    </div>
  )
}

export default About