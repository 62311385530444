import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { api } from '../../../api';
import { Empty } from 'antd';

// Enregistrement des éléments nécessaires dans ChartJS
ChartJS.register(ArcElement, Tooltip, Legend);

const ChartCercle = ({mois, annee}) => {

  const [loading, setLoading] = useState(true);

  const user_id = sessionStorage.getItem('admin_id');
  const [debut60, setDebut60] = useState(0);
  const [debut61, setDebut61] = useState(0);
  const [debut21, setDebut21] = useState(0);
  const [debut62, setDebut62] = useState(0);
  const [debut63, setDebut63] = useState(0);
  const [debut64, setDebut64] = useState(0);
  const [debut65, setDebut65] = useState(0);

  // Récuperer les données dans le chart cercle
  const getDonneeChartCercle = async () => {
    try {
      const response = await axios.get(api + `/api/getDonneeChartCercle/${mois}/${annee}/${user_id}`);
      setDebut60(Number(response.data.debut60));
      setDebut61(Number(response.data.debut61));
      setDebut21(Number(response.data.debut21));
      setDebut62(Number(response.data.debut62));
      setDebut63(Number(response.data.debut63));
      setDebut64(Number(response.data.debut64));
      setDebut65(Number(response.data.debut65));

      setLoading(false)

      console.log(response.data);
      // setLoading(false);

    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };
  
  useEffect(() => {
    getDonneeChartCercle();
  }, []);

  const data = {
    labels: ['Fampitaovana maharitra', 'Fandaniana mivantana momba ny varotra', 'Fandaniana hafa ilaina mikasika ny varotra', 'Fandaniana hafa ilaina nataon\'ny olon-kafa', 'Hetra', 'Karaman\'ny mpiasa', 'Fandaniana hafa'],
    datasets: [
      {
        label: '# of Votes',
        data: [debut21, debut60, debut61, debut62, debut63, debut64, debut65],
          backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(95, 25, 152, 0.6)',
          'rgba(125, 99, 132, 0.6)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(95, 25, 152, 1)',
          'rgba(125, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: { position: 'top' },
  //     tooltip: {
  //       callbacks: {
  //         label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}`,
  //       },
  //     },
  //   },
  // };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top', // Positionne les labels à droite
        align: 'start',    // Aligne verticalement les labels
      },
      tooltip: {
        callbacks: {
          // Affichage du pourcentage dans le tooltip
          label: (tooltipItem) => {
            const total = tooltipItem.dataset.data.reduce((acc, value) => acc + value, 0);
            const value = tooltipItem.raw;
            const percentage = ((value / total) * 100).toFixed(2); // Calcul du pourcentage
            return `${tooltipItem.label}: ${percentage}%`;
          },
        },
      },
    },
  };

  return loading ? (
              <>
                <div className='justify-center items-center flex mt-28'>
                  <div className="w-10 h-10 justify-center items-center border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                </div>
              </>
  ) : (debut60 === 0 && debut61 === 0 && debut21 === 0 && debut62 === 0 && debut63 === 0 && debut64 === 0 && debut65 === 0 ? 
    <div className='flex flex-col items-center justify-center h-full p-2'>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span className='text-lg'>Vous n'avez pas encore des données. <br /></span>}
        />
    </div>
    : 
    <Pie data={data} options={options} />)
};

export default ChartCercle;
