import React, { useEffect, useState } from 'react'
import SideBar from '../../../Components/Utilisateur/SideBar'
import Header from '../../../Components/Utilisateur/Header'
import {Modal} from 'antd'
import { useNavigate } from 'react-router-dom'
import {PlusOutlined} from '@ant-design/icons'
import { api } from '../../../api'
import axios from 'axios'
import { Button, message, Space } from 'antd';

const AnneeFiscale = () => {

  const user_id = sessionStorage.getItem('admin_id');
  const [loading, setLoading] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Année fiscale ajouté avec succès !',
    });
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [anneeFiscale, setAnneeFiscale] = useState('')

  const showModal = () => {
    setOpen(true);
  };
  const navigate = useNavigate()

  const choixMois = (mois) => {
    navigate('/utilisateur/anneeFiscale/' + mois)
  };

  const handleOk = () => {
    setConfirmLoading(true);

    const formData = new FormData();
    formData.append('annee', anneeFiscale);
    formData.append('user_id', user_id)
    
    try {
      axios.post(api + '/api/ajoutAnneeFiscale', formData)
          .then(res => {
              success();
              // Gérer la réponse en cas de succès
              console.log('Ajout année fiscale réussie:', res.data);
              getAllAnneeFiscale()
              setAnneeFiscale('')

              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setOpen(false);
  };

  // const data = [
  //   { id: 1, annee: '2024' },
  //   { id: 2, annee: '2023'},
  //   { id: 3, annee: '2022'},
  //   { id: 3, annee: '2021'},
  // ];

  // Tous les années fiscales
  const getAllAnneeFiscale = async () => {
    try {
      const response = await axios.get(api + `/api/allAnneeFiscale/${user_id}`);
      setData(response.data);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching code journal:', error);
    }
  };

  useEffect(() => {
    getAllAnneeFiscale();
  }, []);

  const [data, setData] = useState([])

  return (
    <div className='bg flex flex-row ' style={{ background: '#F9F9FC' }} >
      {contextHolder}
      <div>
        <SideBar />
      </div>
      <div className='bg w-full h-full' style={{ background: '#F9F9FC', height: '100vh' }}>
        <div>
          <Header titre={'Année fiscale'}/>
        </div>

        {/* <div className='bg pl-8 pr-7 flex gap-36 py-7'>
          <div onClick={showModal} className=' border-secondary bg-primary rounded-xl border-2 cursor-pointer duration-300 p-5 w-1/4 text-center text-3xl font-bold'>
            <span className='text-white'>+</span>
          </div>
          <div onClick={() => choixMois(2024)} className=' border-secondary rounded-xl border-2 cursor-pointer duration-300 p-5 w-1/4 text-center text-3xl font-bold'>
            <span className=''>2024</span>
          </div>
          <div onClick={() => choixMois(2023)} className=' border-secondary rounded-xl border-2 cursor-pointer duration-300 p-5 w-1/4 text-center text-3xl font-bold'>
            <span className=''>2023</span>
          </div>
          <div className=' border-secondary rounded-xl border-2 cursor-pointer duration-300 p-5 w-1/4 text-center text-3xl font-bold'>
            <span className=''>2022</span>
          </div>
        </div> */}

            <div className='pl-8 pr-7 py-7'>
                {loading ? (
                  // Spinner de chargement avec TailwindCSS
                  <div className="flex justify-center items-center h-32">
                    <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                  </div>
                ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-32 gap-y-10 py-4 mt-4">
                    <div onClick={showModal} className="shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer font-bold bg-primary">
                      <p className="text-white"><PlusOutlined style={{ fontSize: '25px' }}/></p>
                    </div>
                  {data.map((data, index) => (
                    <div onClick={() => choixMois(data.annee)} key={index} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                      <p className="font-semibold text-xl">{data.annee}</p>
                    </div>
                  ))}
                </div>
                )}
            </div>

      </div>

      <Modal
        title= {(<div className='text-center'><span className='text-lg'>Ajout d'année fiscale</span></div>)}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel} footer={(
          <div className="flex justify-center space-x-4">
            <button 
              onClick={handleCancel} 
              className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
            >
              Annuler
            </button>
            <button 
              onClick={handleOk} 
              className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              disabled={confirmLoading}
            >
              {confirmLoading ? 'En cours...' : 'Ajouter'}
            </button>
          </div>
        )}
      >
            <div className='space-y-2 mt-8 mb-8'>
              <label htmlFor="email" className="font-semibold">Année</label>
              <input
                id="anneeFiscale"
                name="anneeFiscale"
                type="number"
                required
                onChange={(e) => setAnneeFiscale(e.target.value)}
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Insérez l'année"
              />
            </div>
      </Modal>
      
    </div>
  )
}

export default AnneeFiscale