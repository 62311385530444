import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import axios from 'axios'
import { api } from '../../../api'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
  const navigate = useNavigate()
  const user_id = sessionStorage.getItem('user_id');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([])

  // Mon abonnement actuel
  const getAboActuel = async () => {
    try {
      const response = await axios.get(api + `/api/aboActuel/${user_id}`);
      console.log(response.data)
      setData(response.data[0]);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const toFacture = () => {
    navigate('/admin/facture/' + data.id)
  }

  useEffect(() => {
    getAboActuel();
  }, []);
  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Abonnement'}/>
        </div>

        <div className='pl-8 pr-7 py-7'>
          <div className='flex justify-between'>
            <span className='text-lg font-semibold'>Information sur mon abonnement actuel</span>
            <button className='px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2 hover:text-white hover:bg-primary' onClick={toFacture}>Générer un facture</button>
          </div>


          <div className='border-2 mt-10 p-7 rounded-2xl'>

            {
              loading ? (
                  // Spinner de chargement avec TailwindCSS
                  <>
                  
                      <div className="flex justify-center items-center h-32">
                        <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                      </div>
                  </>
              ) : (
                <>
                <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2'>
                    <div className='flex space-x-10'>
                      {/* <span className='text-lg mr-10'>Type : <span className='text-primary text-lg font-semibold'>Standard</span></span> */}
                      <div>
                        <span className='text-lg'>Type :</span>
                      </div>
                      <div>
                        <span className='text-primary text-lg font-semibold'>{data.type}</span>
                      </div>
                    </div>
      
                    <div className='flex space-x-5'>
                      <span className='text-lg'>Prix :</span> 
                      <span className='text-primary text-lg font-semibold'>{parseInt(data.prix).toLocaleString('fr-FR')} Ar</span>
                      <span className='text-lg'>( {data.choix} )</span>
                    </div>
                  </div>
  
                  <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 mt-5'>
                    <div className='space-y-3'>
                      <div>
                        <span className='text-lg'>Date d'activation :</span>
                      </div>
                      <div className='inline-block border-2 border-green-500 px-2 py-1 rounded'>
                        <span>{new Date(data.dateDebut).toLocaleDateString('fr-FR', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                              })}</span>
                      </div>
                    </div>
      
                    <div className='space-y-3'>
                      <div>
                        <span className='text-lg'>Date limite :</span>
                      </div>
                      <div className='inline-block border-2 border-red-600 px-2 py-1 rounded'>
                        <span>{new Date(data.dateFin).toLocaleDateString('fr-FR', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                              })}</span>
                      </div>
                    </div>
                    <div>
  
                </div>
              </div>
  
                <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 mt-3'>
                  <div className='flex space-x-10'>
                    {/* <span className='text-lg mr-10'>Type : <span className='text-primary text-lg font-semibold'>Standard</span></span> */}
                    <div>
                      <span className='text-lg'>Etat :</span>
                    </div>
                    <div>
                      <span className='text-lg text-primary font-semibold'>{data.etat}</span>
                    </div>
                  </div>
  
                
              </div>
              </>
              )
            }

            

            

          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Dashboard

