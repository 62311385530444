import React, { useState } from 'react';
import img1 from '../../Assets/imgInscription.jpg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fonction pour envoyer le lien vers l'email
  const envoyerLien = async () => {
    setError('');  // Réinitialiser les erreurs
    setSuccess('');  // Réinitialiser le message de succès

    try {
      // Envoyer une requête POST à l'API Laravel pour envoyer l'email
      const response = await axios.post('http://localhost:8000/api/envoyerLien', {
        email: email
      });

      // Si la requête réussit, afficher le message de succès et rediriger
      setSuccess('Le lien a été envoyé à votre adresse email.');
      setTimeout(() => {
        navigate('/');
      }, 3000); // Rediriger après 3 secondes
    } catch (error) {
      // Gérer les erreurs de validation
      if (error.response && error.response.data.error) {
        setError(error.response.data.error.email[0]); // Afficher l'erreur d'email
      } else {
        setError('Une erreur est survenue. Veuillez réessayer.');
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {/* Image à gauche */}
      <div className="w-1/2 flex md:p-0 max-sm:hidden">
        <img src={img1} alt="Placeholder" className="w-full object-cover h-auto" />
      </div>

      {/* Formulaire à droite */}
      <div className="w-full md:w-1/2 md:p-8 max-sm:mt-10 max-sm:px-5 mx-auto flex flex-col items-center justify-center h-screen">
        <h2 className="text-2xl font-bold mb-2 text-center md:text-left">Etape 1</h2>
        <h2 className="text-2xl font-bold mb-4 text-center md:text-left">Vérification d'email</h2>
        <p className='text-center lg:px-20 md:mb-7'>
          Un lien de confirmation sera envoyé à votre adresse email. Veuillez cliquer sur ce lien pour continuer votre inscription.
        </p>

        {/* Afficher les messages d'erreur ou de succès */}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">{success}</p>}

        <form className="w-full lg:px-20">
          <div className="mb-6">
            <label className="block mb-2 font-semibold">Email</label>
            <input
              type="email"
              className="w-full border rounded-lg border-gray-300 p-2"
              placeholder="Entrez votre email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}  // Mettre à jour l'état de l'email
            />
          </div>
          <button
            type="button"
            onClick={envoyerLien}
            className="bg-primary text-white px-4 py-2 font-semibold rounded w-full"
          >
            Envoyer
          </button>

          {/* <div class="flex items-center justify-center">
              <div class="animate-spin rounded-full h-7 w-10 border-b-4 border-blue-500 border-t-transparent"></div>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default VerifyEmail;
