import React, { useEffect, useState } from 'react'
import SideBar from '../../../Components/SuperAdmin/SideBar'
import Header from '../../../Components/SuperAdmin/Header'
import {EditOutlined, DeleteOutlined, ExclamationCircleFilled,FilterOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import { api } from '../../../api'
import { Button, message, Space } from 'antd';
import axios from 'axios'

const Historique = () => {

  const user_id = sessionStorage.getItem('user_id');
  const [loading, setLoading] = useState(false);

  const data = [
    { id: 1, date: '22 Septembre 2024', action: 'Ajout', detail: 'Ajout d\'un plan comptable', utilisateur: 'RANDRIA Assil'},
    { id: 1, date: '22 Septembre 2024', action: 'Ajout', detail: 'Ajout d\'un plan comptable', utilisateur: 'RANDRIA Assil'},
    { id: 1, date: '23 Septembre 2024', action: 'Modification', detail: 'modification d\'un plan comptable', utilisateur: 'ZAFY Lucio'},
    { id: 1, date: '23 Septembre 2024', action: 'Ajout', detail: 'Ajout d\'un plan comptable', utilisateur: 'RANDRIA Assil'},
    { id: 1, date: '24 Septembre 2024', action: 'Ajout', detail: 'Ajout d\'un journal de saisie', utilisateur: 'ZAFY Lucio'},
  ];

  // Tous les codes journals
  // const getAllCodeJournal = async () => {
  //   try {
  //     const response = await axios.get(api + '/api/allCodeJournalGlobal');
  //     setData(response.data);
  //     setLoading(false);

  //   } catch (error) {
  //     console.error('Error fetching code journal:', error);
  //   }
  // };


  // useEffect(() => {
  //   getAllCodeJournal();
  // }, []);

  // Ajout code journal
  // const handleOk = () => {
  //   setConfirmLoading(true);

  //   const formData = new FormData();
  //   formData.append('libelle', codeJournale.libelle);
  //   formData.append('abreviation', codeJournale.abreviation);
  //   formData.append('user_id', user_id)
  //   formData.append('etat', 'global')
    
  //   try {
  //     axios.post(api + '/api/ajoutCodeJournal', formData)
  //         .then(res => {
  //             success();
  //             // Gérer la réponse en cas de succès
  //             console.log('Ajout code journal réussie:', res.data);
  //             getAllCodeJournal()
  //             setCodeJournale({
  //               ...codeJournale,
  //               libelle: '', abreviation: ''  
  //             });
              
  //         })
  //         .catch(err => {
  //             // Gérer les erreurs de la requête
  //             if (err.response) {
  //                 // Le serveur a répondu avec un code de statut différent de 2xx
  //                 console.error('Erreur réponse serveur:', err.response.data);
  //             } else if (err.request) {
  //                 // La requête a été faite, mais aucune réponse reçue
  //                 console.error('Aucune réponse reçue:', err.request);
  //             } else {
  //                 // Autre erreur lors de la configuration de la requête
  //                 console.error('Erreur lors de la requête:', err.message);
  //             }
  //         });
  //   } catch (error) {
  //       // Gérer les erreurs inattendues dans le bloc try
  //       console.error('Erreur inattendue:', error);
  //   }

  //   setTimeout(() => {
  //     setOpen(false);
  //     setConfirmLoading(false);
  //   }, 2000);
  // };

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Historique'}/>
        </div>
        <div className='pl-8 pr-7 py-4'>
        <div className='flex justify-between'>
            <div className='flex space-x-5'>
                <div>
                  <span className='font-semibold text-lg'>Filtre <span><FilterOutlined /></span> :</span>
                </div>
                <div>
                  {/* <input type="number" placeholder='Par groupement' className='border border-primary rounded-lg w-44 px-2 text-lg' /> */}
                  <input
                    type="text"
                    placeholder="Filtrer par utilisateur"
                    // value={searchTerm}
                    // onChange={(e) => setSearchTerm(e.target.value)}
                    className="px-1 border border-primary rounded"
                  />
                </div>
              </div>

              
          </div>
          {/* Table */}
          <div className="overflow-x-auto mt-5 rounded-xl">
            {loading ? (
              // Spinner de chargement avec TailwindCSS
              <div className="flex justify-center items-center h-32">
                <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
              </div>
            ) : (
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr className="bg-gray-300 border-b">
                    <th className="py-2 px-4 text-left">Date</th>
                    <th className="py-2 px-4 text-left">Action</th>
                    <th className="py-2 px-4 text-left">Détail</th>
                    <th className="py-2 px-4 text-left">Utilisateur</th>
                    <th className="py-2 px-4 text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((historique) => (
                    <tr key={historique.id} className="border-b">
                      <td className="py-2 px-4">{historique.date}</td>
                      <td className="py-2 px-4">{historique.action}</td>
                      <td className="py-2 px-4">{historique.detail}</td>
                      <td className="py-2 px-4">{historique.utilisateur}</td>
                      <td className="py-2 px-4 space-x-4">
                        Actions
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

        </div>
      </div>

      {/* Modal d'ajout */}
      {/* <Modal title="Ajout d'un code journal" open={open} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel} footer={(
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={handleCancel} 
                  className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button
                  onClick={handleOk} 
                  className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  disabled={confirmLoading}
                >
                  {confirmLoading ? 'En cours...' : 'Enregistrer'}
                </button>
              </div>
            )}
          >
                <div className='space-y-2 mt-8 mb-4'>
                  <label className="font-semibold">Libellé</label>
                  <input
                    id="libelle"
                    name="libelle"
                    type="text"
                    onChange={handleInputChange}
                    required
                    value={codeJournale.libelle}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la libellé"
                  />
                </div>

                <div className='space-y-2 mt-3 mb-8'>
                  <label className="font-semibold">Abréviation</label>
                  <input
                    id="abreviation"
                    name="abreviation"
                    type="text"
                    onChange={handleInputChange}
                    required
                    value={codeJournale.abreviation}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'abréviation"
                  />
                </div>
      </Modal> */}
      
    </div>
  )
}

export default Historique

