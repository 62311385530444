import React, { useEffect, useRef, useState } from 'react'
import SideBarKaonty from '../../../Components/Utilisateur/SideBar'
import Header from '../../../Components/Utilisateur/Header'
import {LeftCircleOutlined} from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import html2pdf from 'html2pdf.js';
import axios from 'axios'
import { api } from '../../../api'
import { Empty } from 'antd';

const BalanceGenerale = () => {

  const exportRef = useRef(); // Reference for the div to export
  const user_id = sessionStorage.getItem('admin_id');

  const {mois, annee} = useParams()
  // Récuperer le nom du mois
  const moisNom = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  const moisIndex = parseInt(mois) - 1;
  const nomMois = moisNom[moisIndex] || "Mois inconnu";

  const [loading, setLoading] = useState(true);
  const [dataExist, setDataExist] = useState(false);
  const [data, setData] = useState([]);

  const navigate = useNavigate()

  const revenir = () => {
    navigate('/utilisateur/anneeFiscale/'+ annee + '/' + mois)
  }

  const handleExportPDF = () => {
    const element = exportRef.current;
    html2pdf()
      .from(element)
      .set({
        margin: 1,
        filename: 'balance_generale.pdf',
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'portrait' }
      })
      .save();
  };

  // Récuperer les informations dans le rapport financier
  const getBalanceGenerale = async () => {
    try {
      const response = await axios.get(api + `/api/getBalanceGenerale/${mois}/${annee}/${user_id}`);

      setLoading(false)

      if (response.data.existe)
      {
        setData(response.data.balanceGenerale)
      } else {
        setDataExist(true)
      }

      console.log(response.data);
      // setLoading(false);

    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };

  useEffect(() => {
    getBalanceGenerale();
  }, []);

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Balance générale'}/>
        </div>

        {/* Bouton pour exporter en PDF */}
        <div className=' pr-7 py-6'>
         <div className='flex justify-between'>
          <div className='ml-8'>
            <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
          </div>
          <div>
            <button
              onClick={handleExportPDF}
              className='bg-blue-500 text-white py-1 px-3 rounded'
            >
              Exporter en PDF
            </button>
          </div>
         </div>

          {
            loading ? (
              <>
                <div className='justify-center items-center flex mt-28'>
                  <div className="w-10 h-10 justify-center items-center border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                </div>
              </>
            ) : (
              <>

                {
                  dataExist ? (
                    <div className='flex flex-col items-center justify-center h-full p-2 mt-44'>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span className='text-lg'>Vous n'avez pas encore des données. <br /></span>}
                        />
                    </div>
                  ) : (

                      <>

                          {/* Contenu à exporter en PDF */}
                            <div ref={exportRef} className='pl-8 py-2'>
                              <div className='text-center mb-5 font-bold'>
                                <span>BALANCE GENERALE</span>
                              </div>
                              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 '>
                                <div className='grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 gap-5'>
                                  <div className='flex flex-col font-semibold space-y-3'>
                                    <div className='space-x-7'><span>MOIS DE :</span> <span>{nomMois}</span></div>
                                  </div>
                                </div>

                                <div className='grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 gap-5 font-semibold'>
                                <div className='flex flex-col font-semibold space-y-3'>
                                    <div className='space-x-7'><span>ANNEE FISCALE :</span><span>{annee}</span></div>
                                  </div>
                                </div>
                              </div>

                              {/* Tableau */}
                              <div className=''>
                                <table className='min-w-full border-collapse bg-white mb-5 mt-5'>
                                  <thead>
                                    <tr className='bg-primary'>
                                      <th className=' border-black w-1/2 px-2 py-2 text-white'>Compte</th>
                                      <th className='w-1/6 px-4 py-2  border-black text-white text-right'>Débit</th>
                                      <th className='w-1/6 px-4 py-2  border-black text-white text-right'>Crédit</th>
                                      <th className='w-1/6 px-4 py-2 text-white text-right'>Solde</th>
                                    </tr>
                                  </thead>

                                  <tbody>

                                    {
                                      data.map((balance, index) => (
                                        <tr key={balance.pcg_id} className={`border-b ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                          <td className=' border-black w-1/2 px-2 py-2 space-x-5'><span>{balance.pcg.numCompte}</span> <span>{balance.pcg.libelle}</span></td>
                                          <td className=' border-black w-1/6 px-2 py-2 pr-3 space-x-5 text-right'>{((parseFloat(balance.total_debit)) + (parseFloat(balance.total_entree))).toLocaleString('fr-FR')}</td>
                                          <td className=' border-black w-1/6 px-2 py-2 pr-3 space-x-5 text-right'>{((parseFloat(balance.total_credit)) + (parseFloat(balance.total_sortie))).toLocaleString('fr-FR')}</td>
                                          <td className=' border-black w-1/6 px-2 py-2 pr-3 space-x-5 text-right'>{(((parseFloat(balance.total_debit)) + (parseFloat(balance.total_entree)))- ((parseFloat(balance.total_credit)) + (parseFloat(balance.total_sortie)))) .toLocaleString('fr-FR')}</td>
                                        </tr>
                                      ))
                                    }
                                    
                                    {/* <tr className='border-b'>
                                      <td className=' border-black w-1/2 px-2 py-2'>1080 Compte de l'exploitant (Vola ampiasain'ny mpitantana)</td>
                                      <td className=' border-black w-1/6 text-center px-2 py-2'>12 000</td>
                                      <td className=' border-black w-1/6 text-center px-2 py-2'>2 000</td>
                                      <td className=' border-black w-1/6 text-center px-2 py-2'>10 000</td>
                                    </tr> */}

                                  </tbody>
                                </table>
                              </div>

                            </div>

                      </>

                  )
                }

              </>
            )
          }

          

        </div>
      </div>
      
    </div>
  )
}

export default BalanceGenerale

