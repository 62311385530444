import React, { useEffect, useState } from 'react';
import SideBar from '../../../Components/SuperAdmin/SideBar';
import Header from '../../../Components/SuperAdmin/Header';
import { LeftCircleOutlined , CloseOutlined} from '@ant-design/icons';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../../../api';
import axios from 'axios';
import {Modal} from 'antd'
import { Button, message as antdMessage } from 'antd';

const DetailDemande = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour le modal d'image

  const [messageApi, contextHolder] = antdMessage.useMessage();
  const { confirm } = Modal;
  const successAcceptation = () => {
    messageApi.open({
      type: 'success',
      content: 'Demande d\'abonnement acceptée avec succès !',
    });
  };

  const successRefus = () => {
    messageApi.open({
      type: 'success',
      content: 'Demande d\'abonnement refusée avec succès !',
    });
  };

  const revenir = () => {
    navigate('/superAdmin/demande');
  };

  const getInfoDemande = async () => {
    try {
      const response = await axios.get(api + `/api/infoDemande/${id}`);
      setData(response.data[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    getInfoDemande();
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Modal de confirmation de refus du demande d'abonnement d'un client
  const showConfirmRefuser = (id, nomClient, prenomClient) => {
    confirm({
      title: 'Voulez-vous vraiment refuser la demande d\'abonnement du client: ' + nomClient + ' ' + prenomClient +  ' ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, refuser', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'red', borderColor: 'primary', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        // console.log('Supprimer');
        // alert('Refusé ' + id)
        refuserDemande(id)
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  // Modal de confirmation d'acceptation du demande d'abonnement d'un client
  const showConfirmAccepter = (id, nomClient, prenomClient) => {
    confirm({
      title: 'Voulez-vous vraiment accepter la demande d\'abonnement du client: ' + nomClient + ' ' + prenomClient +  ' ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, accepter', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, annuler',
      okButtonProps: {
        style: { backgroundColor: 'primary', borderColor: 'primary', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        // console.log('Supprimer');
        // alert('Accepté ' + id)
        accepterDemande(id)
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  // Accepter une demande
  const accepterDemande = async (id) => {
    try {
      const form = new FormData()
          form.append("type", data.type)
          form.append("choix", data.choix)
          form.append("prix", data.prix)
          form.append("user_id", data.user?.id)

      const response = await axios.post(api + `/api/accepterDemande/${id}`, form);
      // setData(response.data);
      successAcceptation();
      getInfoDemande()

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Refuser une demande
  const refuserDemande = async (id) => {
    try {
      const response = await axios.post(api + `/api/refuserDemande/${id}`);
      // setData(response.data);
      successRefus();
      getInfoDemande()

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      {contextHolder}
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Demande'} />
        </div>
        <div className='pl-8 pr-7 py-6'>
          <div className='j justify-between'>
            <div className='space-x-3'>
              <span onClick={revenir}>
                <LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer' />
              </span>
              <span className='text-lg font-semibold'>
                Détails d'une demande d'abonnement de : <span className='text-primary'>{data.user?.nom} {data.user?.prenom}</span>
              </span>
            </div>
          </div>

          <div className='border-2 mt-8 p-7 rounded-2xl'>
            {loading ? (
              <div className="flex justify-center items-center h-32">
                <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 gap-x-16 py-2">
                  <div className="py-2 text-left space-y-2">
                    <p className="font-semibold">Message</p>
                    <p>{data.message}</p>
                  </div>
                  <div className="py-2 text-left space-y-2">
                    <p className="font-semibold">Objet</p>
                    <p>{data.objet}</p>
                  </div>
                  <div className="py-2 text-left space-y-2">
                    <p className="font-semibold">Type</p>
                    <p>{data.type}</p>
                  </div>
                  <div className="py-2 text-left grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                    <div className='space-y-2'>
                      <p className="font-semibold">Choix</p>
                      <p>{data.choix}</p>
                    </div>
                    <div className='space-y-2'>
                      <p className="font-semibold">Prix</p>
                      <p>{(data.prix).toLocaleString('fr-FR')} Ar</p>
                    </div>
                  </div>
                  <div className="py-2 text-left space-y-2">
                    <p className="font-semibold">Date</p>
                    <p>{data.date}</p>
                  </div>
                  <div className="py-2 text-left space-y-2">
                    <p className="font-semibold">Etat</p>
                    <p className={`border-2 inline-block px-2 rounded-full ${data.etat === 'EN ATTENTE' ? 'border-primary' : ''}
                      ${data.etat === 'REFUSEE' ? 'border-red-600' : '' } ${data.etat === 'ACCEPTEE' ? 'border-green-500' : '' }`}>{data.etat}</p>
                  </div>
                  <div className="py-2 text-left space-y-2">
                    <p className="font-semibold">Référence de paiement</p>
                    <p>{data.ref}</p>
                  </div>
                  <div className="py-2 text-left space-y-2">
                    <p className="font-semibold">Image</p>
                    <button
                      onClick={toggleModal}
                      className='px-2 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2 hover:text-white hover:bg-primary'
                    >
                      Voir l'image
                    </button>
                  </div>
                </div>

                {/* Des boutons */}
                <div className='flex justify-between mt-8'>
                  <div></div>
                  <div className='flex space-x-8'>
                    <button className={`px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2 hover:text-white hover:bg-primary ${data.etat === 'ACCEPTEE' ? 'hidden' : ''}`}
                    onClick={() => showConfirmAccepter(data.id, data.user?.nom, data.user?.prenom)}>
                      Accepter
                    </button>
                    <button className={`px-4 py-1 font-semibold bg-white text-red-500 duration-300 border-red-500 rounded-lg border-2 hover:text-white hover:bg-red-500 ${data.etat === 'REFUSEE' ? 'hidden' : ''}`}
                    onClick={() => showConfirmRefuser(data.id, data.user?.nom, data.user?.prenom)}>
                      Réfuser
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Modal pour afficher l'image */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg w-full">
              <div className='flex justify-between'>
                <h2 className="text-lg font-semibold mb-4">Image du capture d'écran du paiement</h2>
                <div>
                  {/* <button
                    onClick={toggleModal}
                    className="px-4 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600"
                  >
                    Fermer
                  </button> */}
                  <CloseOutlined className='border rounded-xl cursor-pointer bg-red-400 text-white hover:bg-red-500 p-2' onClick={toggleModal}/>
                </div>
              </div>
              <img src={api + '/storage/' + data.image} alt="Image de la demande" className="w-full h-auto mb-4" />
              
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailDemande;
