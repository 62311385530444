import React, { useEffect, useRef } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../api'
import axios from 'axios'
import {LeftCircleOutlined} from '@ant-design/icons'
import html2pdf from 'html2pdf.js';
import logoIkaonty from '../../../Assets/Logo.png'
import logoHaisoa from '../../../Assets/haisoa.jpg'

const Facture = () => {
  const exportRef = useRef(); // Reference for the div to export
  const navigate = useNavigate()
  const revenir = () => {
    navigate('/admin/actuel')
  }

  const handleExportPDF = () => {
    const element = exportRef.current;
    html2pdf()
      .from(element)
      .set({
        margin: 1,
        filename: 'facture.pdf',
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'portrait' }
      })
      .save();
  };
  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Abonnement'}/>
        </div>

        <div className='pr-7 py-6'>
          <div className='flex justify-between'>
            <div className='ml-8'>
              <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
            </div>
            <div>

                        <button
                          onClick={handleExportPDF}
                          className='bg-blue-500 text-white py-1 px-3 rounded'
                        >
                          Exporter en PDF
                        </button>
            </div>
          </div>

          <div ref={exportRef} className='pl-5 py-6 ml-8 pr-5 mt-5 bg-white'>
            <div className='flex justify-between'>
              <div className=''>
                <img src={logoHaisoa} alt="" className='w-40'/>
                {/* <span className='text-sm font-semibold'>HAISOA</span><br /> */}
                <span className='text-sm'>www.haisoa.com</span><br />
                <span className='text-md'>Lot VK 17 A Fenomanana Mahazoarivo</span>
              </div>

              <div className='text-center mb-8'>
                <img src={logoIkaonty} alt="" className='w-40'/>
                <span className='text-sm font-semibold'>I-KAONTY</span><br />
                <span className='text-sm'>www.ikaonty.com</span>
              </div>
            </div>

            <div className='text-center text-md'>
              <span>RANDRIANASOLO Manampamonjy Luciano</span><br />
              <span>0348172865</span><br />
              <span>Analakely Antananarivo</span><br />
            </div>

            <hr className='mt-6 mb-2'/>

            <div className='flex justify-between'>
              <div className='mt-2'>
                {/* <span className='text-4xl text-primary font-semibold'>PAYEE</span> */}
              </div>
              <div>
                <span className='mr-10 font-semibold'>Date de facture :</span> <span>21-11-2024</span><br />
                <span className='mr-8 font-semibold'>Moyen de paiement :</span> <span>Espèces</span>
              </div>
            </div>

            <hr className='mt-4 mb-2'/>  

            <div className='mt-7'>
              <span className='text-2xl font-semibold text-primary'>Facture n°2/21-11-2024</span>
            </div>

            <div className="overflow-x-auto mt-5">
          
              <table className="min-w-full bg-white border-gray-200">
                <thead>
                  <tr className="bg-white border-b border-b-gray-500">
                    <th className="py-2 px-4 text-left">Type d'abonnement</th>
                    <th className="py-2 px-4 text-left">Choix</th>
                    <th className="py-2 px-4 text-left">Date début</th>
                    <th className="py-2 px-4 text-left">Date d'expiration</th>
                    <th className="py-2 px-4 text-left">Prix</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className=' bg-gray-100'>
                    <td className='py-2 px-4'>Standard</td>
                    <td className='py-2 px-4'>Annuel</td>
                    <td className='py-2 px-4'>21-11-2024</td>
                    <td className='py-2 px-4'>21-11-2025</td>
                    <td className='py-2 px-4'>600 000 Ar</td>
                  </tr>
                </tbody>
              </table>
            
            </div>

            <div className='flex justify-between mt-6 mr-14'>
              <div>

              </div>
              <div>
                <span className='font-semibold'>Total: </span> <span className='font-semibold'>600 000 Ar</span>
              </div>
            </div>

          </div>

          

        </div>
      </div>
    </div>
  )
}

export default Facture