import React from 'react'
import NavBarAbo from '../../Components/NavBarAbo'
import Footer from '../../Components/Footer'
import ContenuDemande from '../../Components/ContenuDemande'

const Demande = () => {
  return (
    <div>
      <NavBarAbo/>
      <ContenuDemande/>
      <Footer/>
    </div>
  )
}

export default Demande