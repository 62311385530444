import React from 'react'

import SideBar from '../../../Components/SuperAdmin/SideBar'
import Header from '../../../Components/SuperAdmin/Header'
import { UserOutlined, ClockCircleOutlined, CheckCircleOutlined, UsergroupAddOutlined } from '@ant-design/icons';

const Dashboard = () => {

  // const user_id = sessionStorage.getItem('user_id');
  // const token = sessionStorage.getItem('token');

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <Header titre={'Bonjour Assil'}/>

        <div className='w-full grid grid-cols-4 justify-start items-start gap-x-6 px-7 pt-6'>
                <div className='w-full p-3 flex flex-row justify-between items-start bg-white border rounded-lg border-l-primary border-l-4'>
                    <div className='flex flex-col gap-y-1'>
                        <p className='font-semibold text-lg'>Utilisateur</p>
                        {/* <h4 className='text-3xl'>{user.length}</h4> */}
                        <h4 className='text-lg'>5</h4>
                    </div>
                    <div className='p-3 bg-redColor/10 rounded-full h-full border border-redColor text-redColor'>
                        {/* <FaUsers /> */}
                        <UserOutlined style={{ fontSize: '28px', color: '#1890ff' }} />
                    </div>
                </div>
                <div className='w-full p-3 flex flex-row justify-between items-start bg-white border rounded-lg border-l-primary border-l-4'>
                    <div className='flex flex-col gap-y-1'>
                        <p className='font-semibold text-lg'>Demande en attente</p>
                        {/* <h4 className='text-3xl'>{user.length}</h4> */}
                        <h4 className='text-lg'>3</h4>
                    </div>
                    <div className='p-3 bg-redColor/10 rounded-full h-full border border-redColor text-redColor'>
                        {/* <FaUsers /> */}
                        <ClockCircleOutlined style={{ fontSize: '28px', color: '#1890ff' }} />
                    </div>
                </div>
                <div className='w-full p-3 flex flex-row justify-between items-start bg-white border rounded-lg border-l-primary border-l-4'>
                    <div className='flex flex-col gap-y-1'>
                        <p className='font-semibold text-lg'>Demande acceptée</p>
                        {/* <h4 className='text-3xl'>{user.length}</h4> */}
                        <h4 className='text-lg'>2</h4>
                    </div>
                    <div className='p-3 bg-redColor/10 rounded-full h-full border border-redColor text-redColor'>
                        {/* <FaUsers /> */}
                        <CheckCircleOutlined style={{ fontSize: '28px', color: '#1890ff' }} />
                    </div>
                </div>
                <div className='w-full p-3 flex flex-row justify-between items-start bg-white border rounded-lg border-l-primary border-l-4'>
                    <div className='flex flex-col gap-y-1'>
                        <p className='font-semibold text-lg'>Abonnées</p>
                        {/* <h4 className='text-3xl'>{user.length}</h4> */}
                        <h4 className='text-lg'>4</h4>
                    </div>
                    <div className='p-3 bg-redColor/10 rounded-full h-full border border-redColor text-redColor'>
                        {/* <FaUsers /> */}
                        <UsergroupAddOutlined style={{ fontSize: '28px', color: '#1890ff' }} />
                    </div>
                </div>

            </div>

        {/* <div>
          <p>{user_id}</p>
          <p>{token}</p>
        </div> */}
      </div>
      
    </div>
  )
}

export default Dashboard