import React from 'react'
import SideBar from '../../../Components/Utilisateur/SideBar'
import Header from '../../../Components/Utilisateur/Header'
import { useNavigate, useParams } from 'react-router-dom'
import {LeftCircleOutlined} from '@ant-design/icons'

const ChoixMois = () => {

  const navigate = useNavigate()
  const {annee} = useParams()

  const revenir = () => {
    navigate('/utilisateur/anneeFiscale')
  }

  const choixMois = (mois) => {
    navigate('/utilisateur/anneeFiscale/' + annee + '/' + mois)
  }

  const cards = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];

  return (
    <div className='bg flex flex-row'>
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC', height: '100vh' }}>
        <div>
            <Header titre={'Année fiscale'}/>
          </div>
          {/* <div className='bg pl-8 pr-7 flex gap-36 py-7'>
            <button onClick={revenir}>Revenir</button>
            <p>Année : {annee}</p>
          </div> */}
          <div className='pl-8 pr-7 py-7'>
            <div className='flex justify-between'>
              <div className='flex space-x-3'>
                <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
                <p className='f font-semibold'>Veuillez sélectionner un mois :</p>
              </div>
              <div className='flex justify-between space-x-8'>
                <div className='text-center'>
                  <span className='font-bold text-3xl'>{annee}</span>
                </div>
                <div>
                  <button className='px-2 py-1 font-semibold hover:bg-primary hover:text-white duration-300 border-primary rounded-lg border-2'>Bilan annuel</button>
                </div>
              </div>
            </div>
            <div>

            {/* Listes des mois */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-12 py-4 mt-10">
              {/* {cards.map((card, index) => (
                <div onClick={() => choixMois(card)} key={index} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">{card}</p>
                </div>
              ))} */}
                <div onClick={() => choixMois(1)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Janvier</p>
                </div>
                <div onClick={() => choixMois(2)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Février</p>
                </div>
                <div onClick={() => choixMois(3)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Mars</p>
                </div>
                <div onClick={() => choixMois(4)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Avril</p>
                </div>
                <div onClick={() => choixMois(5)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Mai</p>
                </div>
                <div onClick={() => choixMois(6)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Juin</p>
                </div>
                <div onClick={() => choixMois(7)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Juillet</p>
                </div>
                <div onClick={() => choixMois(8)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Août</p>
                </div>
                <div onClick={() => choixMois(9)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Septembre</p>
                </div>
                <div onClick={() => choixMois(10)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Octobre</p>
                </div>
                <div onClick={() => choixMois(11)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Novembre</p>
                </div>
                <div onClick={() => choixMois(12)} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold text-lg">Décembre</p>
                </div>
            </div>

            </div>
          </div>
      </div>
      
      
    </div>
  )
}

export default ChoixMois

