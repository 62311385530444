import React, { useEffect, useState } from 'react'
import SideBar from '../../../Components/SuperAdmin/SideBar'
import Header from '../../../Components/SuperAdmin/Header'
import {EditOutlined, DeleteOutlined, ExclamationCircleFilled,FilterOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import axios from 'axios'
import { api } from '../../../api'
import { Button, message, Space } from 'antd';
import CodeJournale from '../CodeJournale/CodeJournale'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const Dashboard = () => {

  const user_id = sessionStorage.getItem('user_id');
  const [loading, setLoading] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Plan comptable ajouté avec succès !',
    });
  };

  const successSuppression = () => {
    messageApi.open({
      type: 'success',
      content: 'Plan comptable supprimé avec succès !',
    });
  };

  const successMod = () => {
    messageApi.open({
      type: 'success',
      content: 'Plan comptable modifié avec succès !',
    });
  };

  const [data, setData] = useState([])

  const [open, setOpen] = useState(false);
  const [openModalMod, setOpenModalMod] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmLoadingMod, setConfirmLoadingMod] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [groupementFilter, setGroupementFilter] = useState(''); // État pour le filtre de groupement
  // const [filteredData, setFilteredData] = useState(data);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [planComptable, setPlanCommptable] = useState({
    numCompte: '',
    libelle: ''
  })

  const [planComptableMod, setPlanCommptableMod] = useState({
    id: '',
    numCompte: '',
    libelle: ''
  })

  const [dataPerso, setDataPerso] = useState([])

  // const data = [
  //   { id: 1, classe: 1, groupement: 10, numCompte: 101, libelle: 'Capitale propre' },
  //   { id: 1, classe: 1, groupement: 11, numCompte: 110, libelle: 'Trosa' },
  //   { id: 1, classe: 1, groupement: 10, numCompte: 101, libelle: 'Capitale propre' },
  //   { id: 1, classe: 1, groupement: 10, numCompte: 101, libelle: 'Capitale propre' },
  //   { id: 1, classe: 1, groupement: 10, numCompte: 101, libelle: 'Capitale propre' },
  // ];

  const { confirm } = Modal;
  
  // Modal de suppression
  const showConfirm = (pcg) => {
    confirm({
      title: 'Voulez-vous vraiment supprimer ce plan comptable ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal</p> */}
        </div>
      ),
      okText: 'Oui, Supprimer', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, Annuler',
      okButtonProps: {
        style: { backgroundColor: 'red', borderColor: 'red', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        supPlanComptablePerso(pcg.id)
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  const showModal = () => {
    setOpen(true);
  };

  const showModalMod = (pcg) => {
    setOpenModalMod(true);
    setPlanCommptableMod({
      ...planComptableMod,
      libelle: pcg.libelle,
       numCompte: pcg.numCompte, id: pcg.id
    });

  };

  // Tous les plans comptables globals
  const getAllPlanComptable = async () => {
    try {
      const response = await axios.get(api + '/api/allPlanComptableGlobal/' + `?page=${currentPage}`);
      setData(response.data.data);
      setLastPage(response.data.last_page);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching plan comptable:', error);
    }
  };

  // Tous les plans comptables perso
  // const getAllPlanComptablePerso = async () => {
  //   try {
  //     const response = await axios.get(api + '/api/allPlanComptablePerso');
  //     setDataPerso(response.data);
  //     setLoading(false);

  //   } catch (error) {
  //     console.error('Error fetching plan comptable:', error);
  //   }
  // };

  // État pour le filtre (par groupement)
  const [searchTerm, setSearchTerm] = useState('');

  // Filtrage des données en fonction du groupement (en convertissant searchTerm en nombre)
  const filteredData = data.filter((pcg) => {
    // Si le searchTerm est un nombre et le groupement est aussi un entier
    return pcg.groupement.toString().includes(searchTerm);
  });

  useEffect(() => {
    getAllPlanComptable();

    // setFilteredData(
    //   data.filter((item) => 
    //     item.groupement.toString().includes(groupementFilter.toString())
    //   )
    // );
  // }, [currentPage, groupementFilter, data]);
  }, [currentPage]);

  // Ajout d'un plan comptable perso
  const handleOk = () => {
    setConfirmLoading(true);

    const formData = new FormData();
    formData.append('libelle', planComptable.libelle);
    formData.append('numCompte', planComptable.numCompte);
    formData.append('user_id', user_id)
    formData.append('etat', 'global')

    try {
      axios.post(api + '/api/ajoutPlanComptable', formData)
          .then(res => {
              success();
              // Gérer la réponse en cas de succès
              console.log('Ajout plan comptable réussie:', res.data);
              getAllPlanComptable()

              setPlanCommptable({
                ...planComptable,
                libelle: '',
                 numCompte: ''
              });
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlanCommptable({
      ...planComptable,
      [name]: value,
    });
  
  }

  const handleInputChangeNumCompte = (e) => {
    const { name, value } = e.target;

    // Vérifie si l'entrée contient uniquement des chiffres et ne dépasse pas 6 caractères
    if (/^\d*$/.test(value) && value.length <= 6) {
      setPlanCommptable({
        ...planComptable,
        [name]: value,
      });
    }
  
  }

  const handleInputChangeNumCompteMod = (e) => {
    const { name, value } = e.target;

    // Vérifie si l'entrée contient uniquement des chiffres et ne dépasse pas 6 caractères
    if (/^\d*$/.test(value) && value.length <= 6) {
      setPlanCommptableMod({
        ...planComptableMod,
        [name]: value,
      });
    }
  
  }

  const handleInputChangeMod = (e) => {
    const { name, value } = e.target;
    setPlanCommptableMod({
      ...planComptableMod,
      [name]: value,
    });
  
}

  // Suppression d'un plan comptable
  const supPlanComptablePerso = async (planComptable_id) => {
    try {
      axios.post(api + `/api/supPlanComptable/${planComptable_id}`)
          .then(res => {
              successSuppression();
              // Gérer la réponse en cas de succès
              console.log('Suppression du plan comptable réussie:', res.data);
              getAllPlanComptable()
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }
  };

  // Modification plan comptable perso
  const handleOkMod = () => {
    setConfirmLoadingMod(true);

    const formData = new FormData();
    formData.append('libelle', planComptableMod.libelle);
    formData.append('numCompte', planComptableMod.numCompte);
    
    try {
      axios.post(api + `/api/modPlanComptable/${planComptableMod.id}`, formData)
          .then(res => {
              successMod();
              // Gérer la réponse en cas de succès
              // console.log('Ajout code journal réussie:', res.data);
              getAllPlanComptable()
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }

    setTimeout(() => {
      setOpenModalMod(false);
      setConfirmLoadingMod(false);
    }, 2000);
  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleCancelMod = () => {
    // console.log('Clicked cancel button');
    setOpenModalMod(false);
  };

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      {contextHolder}
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Plan comptable'}/>
        </div>
        <div className='pl-8 pr-7 py-4'>
          <div className='flex justify-between'>
            <div className='flex space-x-5'>
                <div>
                  <span className='font-semibold text-lg'>Filtre <span><FilterOutlined /></span> :</span>
                </div>
                <div>
                  {/* <input type="number" placeholder='Par groupement' className='border border-primary rounded-lg w-44 px-2 text-lg' /> */}
                  <input
                    type="number"
                    placeholder="Filtrer par groupement"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-1 border border-primary rounded"
                  />
                </div>
              </div>

              <div>
                <button onClick={showModal} className='px-4 py-1 font-semibold bg-primary text-white duration-300 border-primary rounded-lg border-2'>Ajouter</button>
              </div>
          </div>

          {/* Table */}
          <div className="overflow-x-auto mt-5 rounded-xl">
          
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-300 border-b">
                  <th className="py-2 px-4 text-left">Classe</th>
                  <th className="py-2 px-4 text-left">Groupement</th>
                  <th className="py-2 px-4 text-left">Compte</th>
                  <th className="py-2 px-4 text-left">Libellé</th>
                  {/* <th className="py-2 px-4 text-left">Etat</th> */}
                  <th className="py-2 px-4 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
              {loading ? (
              // Spinner de chargement avec TailwindCSS
                <>
                <tr>
                  <td colSpan='5'>
                    <div className="flex justify-center items-center h-32">
                      <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                    </div>
                  </td>
                </tr>
                </>
              ) : (
                <>
                {filteredData.map((pcg, index) => (
                  <tr key={pcg.id} className={`border-b ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                    <td className="py-2 px-4">{pcg.classe}</td>
                    <td className="py-2 px-4">{pcg.groupement}</td>
                    <td className="py-2 px-4">{pcg.numCompte}</td>
                    <td className="py-2 px-4">{pcg.libelle}</td>
                    {/* <td className="py-2 px-4">{pcg.etat}</td> */}
                    <td className="py-2 px-4 space-x-4">
                      
                        <div className="flex space-x-4">
                          <EditOutlined onClick={() => showModalMod(pcg)} style={{ fontSize: '20px', color: '#1890ff' }} className='c cursor-pointer'/>
                          <DeleteOutlined onClick={() => showConfirm(pcg)} style={{ fontSize: '20px', color: 'red' }} className='c cursor-pointer'/>
                        </div>
                      
                    </td>

                  </tr>
                ))}
                </>
              )}

                {/* {dataPerso.map((pcg) => (
                  <tr key={pcg.id} className="border-b">
                    <td className="py-2 px-4">{pcg.classe}</td>
                    <td className="py-2 px-4">{pcg.groupement}</td>
                    <td className="py-2 px-4">{pcg.numCompte}</td>
                    <td className="py-2 px-4">{pcg.libelle}</td>
                    <td className="py-2 px-4 space-x-4">
                      <EditOutlined onClick={() => showModalMod(pcg)} style={{ fontSize: '20px', color: '#1890ff' }} className='c cursor-pointer'/>
                      <DeleteOutlined onClick={() => showConfirm(pcg)} style={{ fontSize: '20px', color: 'red' }} className='c cursor-pointer'/>
                    </td>
                  </tr>
                ))} */}
              </tbody>
            </table>
            
          </div>

          {/* Pagination */}
        {/* <div className='mt-5'>
          {
            currentPage > 1 && (
              <button className='bg-green-700 hover:bg-green-800 me-3 px-2 py-1 rounded-lg text-white' onClick={() => handlePageChange(currentPage - 1)}>Précedent</button>
            )
          }
          {
            currentPage < lastPage && (
              <button className='bg-green-700 hover:bg-green-800 me-3 px-5 py-1 rounded-lg text-white' onClick={() => handlePageChange(currentPage + 1)}>Next</button>
            )
          }
        </div> */}

          {/* Pagination */}
            <div className='mt-5 flex items-center space-x-2'>
              {/* Bouton Précédent */}
              {currentPage > 1 && (
                <button
                  className='bg-green-700 hover:bg-green-800 px-3 py-1 rounded-lg text-white'
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <LeftOutlined />
                </button>
              )}

              {/* Numéros de page */}
              {Array.from({ length: lastPage }, (_, i) => i + 1)
                .filter((page) => {
                  // Affiche toujours les pages 1 et lastPage
                  if (page === 1 || page === lastPage) return true;
                  // Affiche les pages dans une fourchette autour de la page actuelle
                  return page >= currentPage - 2 && page <= currentPage + 2;
                })
                .map((page, index, pages) => (
                  <>
                    {/* Affiche des points de suspension si l'écart entre les pages est trop grand */}
                    {index > 0 && page > pages[index - 1] + 1 && <span key={`dots-${page}`} className="px-1">...</span>}
                    
                    <button
                      key={page}
                      className={`px-3 py-1 rounded-lg ${
                        page === currentPage ? 'bg-blue-700 text-white' : 'bg-gray-200 text-black hover:bg-gray-300'
                      }`}
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </button>
                  </>
                ))
              }

              {/* Bouton Next */}
              {currentPage < lastPage && (
                <button
                  className='bg-green-700 hover:bg-green-800 px-3 py-1 rounded-lg text-white'
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <RightOutlined />
                </button>
              )}
            </div>



        </div>
      </div>

      {/* Modal d'ajout */}
      <Modal title="Ajout d'un plan comptable" open={open} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel} footer={(
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={handleCancel} 
                  className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button 
                  onClick={handleOk} 
                  className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  disabled={confirmLoading}
                >
                  {confirmLoading ? 'En cours...' : 'Enregistrer'}
                </button>
              </div>
            )}
          >
                <div className='space-y-2 mt-8 mb-4'>
                  <label className="font-semibold">Numéro de compte</label>
                  <input
                    id="numCompte"
                    name="numCompte"
                    type="number"
                    required
                    value={planComptable.numCompte}
                    onChange={handleInputChangeNumCompte}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la numéro de compte"
                  />
                </div>

                <div className='space-y-2 mt-3 mb-8'>
                  <label className="font-semibold">Libellé</label>
                  <input
                    id="libelle"
                    name="libelle"
                    type="texte"
                    required
                    value={planComptable.libelle}
                    onChange={handleInputChange}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la libellé"
                  />
                </div>
      </Modal>

      {/* Modal de modification */}
      <Modal title="Modification d'un plan comptable" open={openModalMod} onOk={handleOkMod} confirmLoading={confirmLoadingMod} onCancel={handleCancelMod}footer={(
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={handleCancelMod} 
                  className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button 
                  onClick={handleOkMod} 
                  className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  disabled={confirmLoadingMod}
                >
                  {confirmLoadingMod ? 'En cours...' : 'Enregistrer'}
                </button>
              </div>
            )}
          >
                <div className='space-y-2 mt-8 mb-4 hidden'>
                  <label className="font-semibold">Id</label>
                  <input
                    id="id"
                    name="id"
                    type="number"
                    value={planComptableMod.id}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  />
                </div>
                <div className='space-y-2 mt-8 mb-4'>
                  <label className="font-semibold">Numéro de compte</label>
                  <input
                    id="numCompte"
                    name="numCompte"
                    type="number"
                    required
                    value={planComptableMod.numCompte}
                    onChange={handleInputChangeNumCompteMod}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la numéro de compte"
                  />
                </div>

                <div className='space-y-2 mt-3 mb-8'>
                  <label className="font-semibold">Libellé</label>
                  <input
                    id="libelle"
                    name="libelle"
                    type="text"
                    required
                    value={planComptableMod.libelle}
                    onChange={handleInputChangeMod}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la libellé"
                  />
                </div>
      </Modal>
      
    </div>
  )
}

export default Dashboard

