import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import { Dropdown, Space } from 'antd';
import { DownOutlined,EllipsisOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../api';
import axios from 'axios';

const Demande = () => {

  const navigate = useNavigate()
  const user_id = sessionStorage.getItem('user_id');

  // const data = [
  //   { id: 1, date: '26 Septembre 2024', type: 'Standard', prix: '50 000 Ar', etat: 'En attente' },
  //   { id: 2, date: '26 Juin 2024', type: 'Standard', prix: '50 000 Ar', etat: 'Accepté' },
  //   { id: 3, date: '26 Septembre 2024', type: 'Standard', prix: '600 000 Ar', etat: 'En attente' },
  //   { id: 4, date: '26 Février 2024', type: 'Standard', prix: '50 000 Ar', etat: 'Accepté' },
  // ];

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);

  // Tous les demandes
  const getAllDemandes = async () => {
    try {
      const response = await axios.get(api + `/api/allDemandeUser/${user_id}`);
      setData(response.data);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    getAllDemandes();
  }, []);

  // Fonction pour rediriger vers la page des détails
  const  detailDemande = (id) => {
    navigate(`/admin/demande/${id}`);
  };

  const  envoyerDemande = () => {
    navigate(`/admin/envoyerDemande`);
  };

  const items = (id) => [
    {
      label: <p className='f font-semibold hover:text-primary' onClick={() => detailDemande(id)}>Détails</p>,
      key: '0',
    }
  ];

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Abonnement'}/>
        </div>
        <div className='pl-8 pr-7 py-6'>
          <div className='flex justify-between'>
            <div>
              <span className='text-lg font-semibold'>Demande(s)</span>
            </div>
            <div>
              <button className='px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2 hover:text-white hover:bg-primary' onClick={envoyerDemande}>Envoyer une demande</button>
            </div>
          </div>

          {/* tableau */}
          <div className="overflow-x-auto mt-5 rounded-xl">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-300 border-b">
                  <th className="py-2 px-4 text-left">Date</th>
                  <th className="py-2 px-4 text-left">Type</th>
                  <th className="py-2 px-4 text-left">Prix (Ar)</th>
                  <th className="py-2 px-4 text-left">Etat</th>
                  <th className="py-2 px-4 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  loading ? (
                      
                    <>
                    <tr>
                      <td colSpan='5'>
                        <div className="flex justify-center items-center h-32">
                          <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                        </div>
                      </td>
                    </tr>
                    </>
                      
                  ) : (
                    data.map((demande) => (
                      <tr key={demande.id} className="border-b">
                        <td className="py-2 px-4">{new Date(demande.date).toLocaleDateString('fr-FR', {
                                                  day: 'numeric',
                                                  month: 'long',
                                                  year: 'numeric',
                                                })}</td>
                        <td className="py-2 px-4">{demande.type}</td>
                        <td className="py-2 px-4">{parseInt(demande.prix).toLocaleString('fr-Fr')}</td>
                        <td className={`py-2 px-4
                            ${demande.etat === 'ACCEPTEE' ? 'text-blue-500' : ''} 
                            ${demande.etat === 'EN ATTENTE' ? 'text-green-500' : ''}`}>
                          <div className={`border inline-block px-2 rounded-full ${demande.etat === 'ACCEPTEE' ? 'border-primary' : ''}
                          ${demande.etat === 'EN ATTENTE' ? 'border-green-500' : ''}`}>
                            {demande.etat}
                          </div>
                        </td>
                        <td className="py-2 px-4 space-x-4 flex justify-center items-center">
                            <Dropdown
                              menu={{
                                items: items(demande.id),
                              }}
                              trigger={['click']}
                            >
                              <a
                                onClick={(e) => e.preventDefault()}
                                className="text-gray-800 font-medium flex items-center space-x-2 hover:text-blue-600"
                              >
                                <Space>
                                  
                                  <EllipsisOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/>
                                </Space>
                              </a>
                            </Dropdown>
                        </td>
                      </tr>
                    ))
                  )
                }
               
              </tbody>
            </table>
          </div>

        </div>
      </div>
      
    </div>
  )
}

export default Demande

