import React, { useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import { useNavigate } from 'react-router-dom'
import {LeftCircleOutlined} from '@ant-design/icons'
import axios from 'axios'
import { api } from '../../../api'
import { Button, message, Space } from 'antd';

const Dashboard = () => {

  const navigate = useNavigate()

  const revenir = () => {
    navigate('/admin/utilisateur')
  }

  const user_id = sessionStorage.getItem('user_id');
  const [messageApi, contextHolder] = message.useMessage();
  const infoIncomplete = () => {
    messageApi.open({
      type: 'info',
      content: 'Veuillez remplir tous les champs s\'il vous plaît.',
    });
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Création du compte d\'utilisateur réussie.',
    });
  };

  const [selectedImage, setSelectedImage] = useState('');
  const [userInfo, setUserInfo] = useState({
    photo: '',
    nom: '',
    prenom: '',
    contact: '',
    adresse: '',
    password: '',
    email: '',
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  
  }

  // Ajouter un utilisateur
  const ajoutUtilisateur = () => {
    if (userInfo.nom === '' || userInfo.prenom === '' || userInfo.contact === '' || userInfo.adresse === '' || userInfo.email === '' || userInfo.password === '')
    {
      infoIncomplete()
    }

    else{
      
      const formData = new FormData();
      formData.append('nom', userInfo.nom);
      formData.append('prenom', userInfo.prenom);
      formData.append('contact', userInfo.contact);
      formData.append('adresse', userInfo.adresse);
      formData.append('password', userInfo.password);
      formData.append('email', userInfo.email);
      formData.append('role', 'utilisateur');
      formData.append('etat', 1);
      formData.append('user_id', user_id);

      if (userInfo.photo) {
        formData.append('photo', userInfo.photo);
      }
      
      try {
        axios.post(api + '/api/ajoutUtilisateur', formData)
            .then(res => {
                success()
                setUserInfo({
                  ...userInfo,
                  nom: '', prenom: '', contact:'', adresse:'', password:'', email:'', photo:''
                });
                
            })
            .catch(err => {
                // Gérer les erreurs de la requête
                if (err.response) {
                    // Le serveur a répondu avec un code de statut différent de 2xx
                    console.error('Erreur réponse serveur:', err.response.data);
                } else if (err.request) {
                    // La requête a été faite, mais aucune réponse reçue
                    console.error('Aucune réponse reçue:', err.request);
                } else {
                    // Autre erreur lors de la configuration de la requête
                    console.error('Erreur lors de la requête:', err.message);
                }
            });
      } catch (error) {
          // Gérer les erreurs inattendues dans le bloc try
          console.error('Erreur inattendue:', error);
      }

    }
  } 

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Créer une URL pour l'image
      setSelectedImage(imageUrl); // Mettre à jour l'image sélectionnée
      // console.log(file)
      // setPhoto(file)
      setUserInfo({
        ...userInfo,
        photo: file,
      })
    }
  };

  // Fonction pour ajouter un compte utilisateur
  const ajoutUser = () => {

    // Vérifier si le mot de passe et la confirmation correspondent
    // if (userInfo.password !== confPassword) {
    //   setErrorMessage('Veuillez confirmer correctement votre mot de passe !');
    //   setConfPassword('')
    //   return;
    // }

    const formData = new FormData();
    formData.append('nom', userInfo.nom);
    formData.append('prenom', userInfo.prenom);
    formData.append('contact', userInfo.contact);
    formData.append('adresse', userInfo.adresse);
    formData.append('password', userInfo.password);
    formData.append('email', userInfo.email);
    formData.append('role', 'utilisateur');
    formData.append('etat', 1);

    if (userInfo.photo) {
      formData.append('photo', userInfo.photo);
    }
    
    try {
      axios.post(api + '/api/ajoutUtilisateur', formData)
          .then(res => {
              // Gérer la réponse en cas de succès
              console.log('Ajout d\'utilisateur réussie:', res.data);
              // success()
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }
  
     // Pour afficher le contenu de formData
    //  formData.forEach((value, key) => {
    //   console.log(`${key}: ${value}`);
    // });

    // console.log(userInfo)
  };

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
       {contextHolder}
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Utilisateur'}/>
        </div>

        <div className='pl-8 pr-7 py-6'>
          <div className='flex justify-between'>
            <div className='flex space-x-3'>
              <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
                <p className='f font-semibold'>Création du compte d'un utilisateur :</p>
            </div>
            <div>
                <button className='px-4 py-1 font-semibold bg-white text-primary duration-300 border-primary rounded-lg border-2 hover:text-white hover:bg-primary' onClick={ajoutUtilisateur}>Enregistrer</button>
              </div>
          </div>

          {/* Div en bas */}
          <div className='b border-2 mt-10 p-7 rounded-2xl'>

          <div className="flex flex-col items-center justify-center">
                  {/* Champ de type file caché */}
                  <input
                    id="fileInput"
                    type="file"
                    className="hidden" 
                    onChange={handleFileChange} 
                  />

                  {/* Label circulaire pour déclencher l'upload */}
                  <label
                    htmlFor="fileInput"
                    className="cursor-pointer flex items-center justify-center w-20 h-20 bg-gray-200 rounded-full overflow-hidden border border-dashed border-gray-400 hover:bg-gray-300"
                  >
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="text-gray-500 text-sm text-center">Sélectionner une image</span>
                    )}
                  </label>
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-20 py-4">
              
                <div className="p-2 text-left space-y-2">
                  <p className="font-semibold">Nom</p>
                  <input type="text" value={userInfo.nom} name='nom' onChange={handleInputChange} required className='border px-2 py-1 rounded-lg w-full' />
                </div>
                <div className="p-2 text-left space-y-2">
                  <p className="font-semibold">Prénom(s)</p>
                  <input type="text" value={userInfo.prenom} name='prenom' onChange={handleInputChange} required className='border px-2 py-1 rounded-lg w-full' />
                </div>
                <div className="p-2 text-left space-y-2">
                  <p className="font-semibold">Email</p>
                  <input type="email" value={userInfo.email} name='email' onChange={handleInputChange} required className='border px-2 py-1 rounded-lg w-full' />
                </div>
                <div className="p-2 text-left space-y-2">
                  <p className="font-semibold">Contact</p>
                  <input type="text" value={userInfo.contact} name='contact' onChange={handleInputChange} required className='border px-2 py-1 rounded-lg w-full' />
                </div>
                <div className="p-2 text-left space-y-2">
                  <p className="font-semibold">Mot de passe</p>
                  <input type="password" value={userInfo.password} name='password' onChange={handleInputChange} required className='border px-2 py-1 rounded-lg w-full' />
                </div>
                <div className="p-2 text-left space-y-2">
                  <p className="font-semibold">Adresse</p>
                  <input type="text" value={userInfo.adresse} name='adresse' onChange={handleInputChange} required className='border px-2 py-1 rounded-lg w-full' />
                </div>
            
            </div>

          </div>

        </div>

      </div>
      
    </div>
  )
}

export default Dashboard

