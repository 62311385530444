import React from 'react'
import SideBar from '../../../Components/Utilisateur/SideBar'
import Header from '../../../Components/Utilisateur/Header'
import { useNavigate, useParams } from 'react-router-dom'
import {LeftCircleOutlined} from '@ant-design/icons'
import ChartCercle from '../Charts/ChartCercle'
import BarChart from '../Charts/BarChart'
import LineChart from '../Charts/LineChart'

const ChoixMenu = () => {

  const navigate = useNavigate()
  const {mois} = useParams()
  const {annee} = useParams()

  const moisNom = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];

  const moisIndex = parseInt(mois) - 1;

  const nomMois = moisNom[moisIndex] || "Mois inconnu";

  const revenir = () => {
    navigate('/utilisateur/anneeFiscale/'+ annee)
  }

  const cards = [
    'Journal de saisie', 'Rapport financier', 'Bilan', 'Balance générale', 'Récapitulation'
  ];

  const choixMenu = (menu) => {
    // navigate('/admin/anneeFiscale/' + annee + '/' + mois)
    alert('Vous avez choisi : ' + menu + ' du ' + mois + ' ' + annee)

  }

  const toJournalSaisie = () => {
    // navigate('/admin/anneeFiscale/' + annee + '/' + mois)
    // alert('Vous avez choisi : ' + menu + ' du ' + mois + ' ' + annee)
    navigate('/Utilisateur/journalSaisie/' + mois + '/' + annee)

  }

  const toBilan = () => {
    // navigate('/admin/anneeFiscale/' + annee + '/' + mois)
    // alert('Vous avez choisi : ' + menu + ' du ' + mois + ' ' + annee)
    navigate('/Utilisateur/bilan/' + mois + '/' + annee)

  }

  const toRecapitulation = () => {
    // navigate('/admin/anneeFiscale/' + annee + '/' + mois)
    // alert('Vous avez choisi : ' + menu + ' du ' + mois + ' ' + annee)
    navigate('/Utilisateur/recapitulation/' + mois + '/' + annee)

  }

  const toBalanceGenerale = () => {
    // navigate('/admin/anneeFiscale/' + annee + '/' + mois)
    // alert('Vous avez choisi : ' + menu + ' du ' + mois + ' ' + annee)
    navigate('/Utilisateur/balanceGenerale/' + mois + '/' + annee)

  }

  const toRapportFinancier = () => {
    // navigate('/admin/anneeFiscale/' + annee + '/' + mois)
    // alert('Vous avez choisi : ' + menu + ' du ' + mois + ' ' + annee)
    navigate('/Utilisateur/rapportFinancier/' + mois + '/' + annee)

  }

  return (
    <div className='bg flex flex-row'>
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Année fiscale'}/>
        </div>
        <div className='pl-8 pr-7 py-6'>
              <div className='flex justify-between'>
                <div className='flex space-x-3'>
                  <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
                  <p className='f font-semibold'>Veuillez sélectionner :</p>
                </div>
                <div className='flex justify-between space-x-4'>
                  <div className='text-center'>
                    <span className='font-bold text-xl'>{nomMois}</span>
                  </div>
                  <div>
                    <span className='font-bold text-xl'>{annee}</span>
                  </div>
                </div>
              </div>

              {/* Listes des menu */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 py-4 mt-3">
              
                <div onClick={toJournalSaisie} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold">Journal de saisie</p>
                </div>
                <div onClick={toRapportFinancier} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold">Rapport financier</p>
                </div>
                <div onClick={toBilan} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold">Bilan</p>
                </div>
                <div onClick={toBalanceGenerale} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold">Balance générale</p>
                </div>
                <div onClick={toRecapitulation} className="bg-white shadow-md rounded-lg border-2 border-primary p-4 text-center cursor-pointer">
                  <p className="font-semibold">Récapitulation</p>
                </div>
            
            </div>

            <div className='mt-6 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 py-4'>

              <div className=''>
                <h1 className='text-center font-semibold'>SITUATION DES DEPENSES</h1>
                {/* <h1 className='text-center font-semibold uppercase'>{mois}</h1> */}
                {/* Affichage du PieChart */}
                <div style={{ width: '450px', height: '350px' }}>
                  <ChartCercle mois={mois} annee={annee}/>
                </div>
              </div>

              <div className=''>
                <h1 className='text-center font-semibold'>RESULTAT DE LA PERIODE PAR RAPPORT AU CA</h1>
                {/* <h1 className='text-center font-semibold uppercase'>{mois}</h1> */}
                {/* Affichage du PieChart */}
                <div className="h-[300px]">
                  <BarChart mois={mois} annee={annee}/>
                </div>
              </div>

              <div className=''>
                <h1 className='text-center font-semibold'>EVOLUTION CA-DEPENSES-RESULTAT</h1>
                {/* <h1 className='text-center font-semibold uppercase'>{mois}</h1> */}
                {/* Affichage du PieChart */}
                <div className="">
                  <LineChart mois={mois} annee={annee}/>
                </div>
              </div>

              
            </div>
              
        </div>
      </div>
      
    </div>
  )
}

export default ChoixMenu

