import React, { useState, useEffect } from 'react';

const Carousel = ({ images }) => {
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStartIndex((prevIndex) => {
        // Recalcule l'index de départ pour les images suivantes
        return (prevIndex + 1) % (images.length - 2); 
        // "-2" pour éviter de dépasser l'index final
      });
    }, 4000); // Change les images toutes les 3 secondes

    return () => clearInterval(interval); // Nettoyage à la fin
  }, [images.length]);

  return (
    <div className="flex overflow-hidden w-full h-[150px]">
      {images.slice(startIndex, startIndex + 3).map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Carousel Image ${index}`}
          className="w-full h-full object-contain" // Changez ici à object-contain
        />
      ))}
    </div>


  );
};

export default Carousel;