import React, { useEffect, useState } from 'react'
import SideBar from '../../../Components/SuperAdmin/SideBar'
import Header from '../../../Components/SuperAdmin/Header'
import {EditOutlined, DeleteOutlined, ExclamationCircleFilled,FilterOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import { api } from '../../../api'
import { Button, message, Space } from 'antd';
import axios from 'axios'

const CodeJournale = () => {

  const user_id = sessionStorage.getItem('user_id');
  const [loading, setLoading] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Code journal ajouté avec succès !',
    });
  };

  const successSuppression = () => {
    messageApi.open({
      type: 'success',
      content: 'Code journal supprimé avec succès !',
    });
  };

  const successMod = () => {
    messageApi.open({
      type: 'success',
      content: 'Code journal modifié avec succès !',
    });
  };

  const [open, setOpen] = useState(false);
  const [openModalMod, setOpenModalMod] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmLoadingMod, setConfirmLoadingMod] = useState(false);
  const [codeJournale, setCodeJournale] = useState({
    libelle: '',
    abreviation: ''
  })

  const [codeJournaleMod, setCodeJournaleMod] = useState({
    id: '',
    libelle: '',
    abreviation: ''
  })
  const [data, setData] = useState([])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCodeJournale({
      ...codeJournale,
      [name]: value,
    });
  
}

  const { confirm } = Modal;
  
  // Modal de suppression
  const showConfirm = (journal) => {
    confirm({
      title: 'Voulez-vous vraiment supprimer ce code de journal ?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {/* <p>Code: {journal.id}</p>
          <p>Description: {journal.abreviation}</p>
          <p>Description: {journal.libelle}</p> */}
          {/* <p>Contenu du modal {journal.id}</p> */}
        </div>
      ),
      okText: 'Oui, Supprimer', // Texte personnalisé pour le bouton OK
      cancelText: 'Non, Annuler',
      okButtonProps: {
        style: { backgroundColor: 'red', borderColor: 'red', color: 'white' }, // Bouton OK en rouge
      },
      onOk() {
        // alert('Supprimé')
        // Fonction de suppression d'un code journal
         
        supCodeJournal(journal.id)
      
      },
      onCancel() {
        // alert('Annulé')
      },
    });
  };

  const showModal = () => {
    setOpen(true);
  };

  const showModalMod = (journal) => {
    setOpenModalMod(true);
    // setCodeJournale.libelle(journal.libelle);
    setCodeJournaleMod({
      ...codeJournaleMod,
      libelle: journal.libelle,
       abreviation: journal.abreviation, id: journal.id
    });

  };

  const handleInputChangeMod = (e) => {
    const { name, value } = e.target;
    setCodeJournaleMod({
      ...codeJournaleMod,
      [name]: value,
    });
  
}

  // const data = [
  //   { id: 1, abreviation: 'CA', libelle: 'Caisse' },
  //   { id: 2, abreviation: 'BQ', libelle: 'Banque' },
  //   { id: 3, abreviation: 'VC', libelle: 'Vente à credit' },
  //   { id: 3, abreviation: 'AC', libelle: 'Achat à crédit' },
  // ];

  // Tous les codes journals
  const getAllCodeJournal = async () => {
    try {
      const response = await axios.get(api + '/api/allCodeJournalGlobal');
      setData(response.data);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching code journal:', error);
    }
  };

  
  // Suppression d'un code journal
  const supCodeJournal = async (codeJournal_id) => {
    try {
      axios.post(api + `/api/supCodeJournal/${codeJournal_id}`)
          .then(res => {
              successSuppression();
              // Gérer la réponse en cas de succès
              console.log('Suppression du code journal réussie:', res.data);
              getAllCodeJournal()
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }
  };

  useEffect(() => {
    getAllCodeJournal();
  }, []);

  // Ajout code journal
  const handleOk = () => {
    setConfirmLoading(true);

    const formData = new FormData();
    formData.append('libelle', codeJournale.libelle);
    formData.append('abreviation', codeJournale.abreviation);
    formData.append('user_id', user_id)
    formData.append('etat', 'global')
    
    try {
      axios.post(api + '/api/ajoutCodeJournal', formData)
          .then(res => {
              success();
              // Gérer la réponse en cas de succès
              console.log('Ajout code journal réussie:', res.data);
              getAllCodeJournal()
              setCodeJournale({
                ...codeJournale,
                libelle: '', abreviation: ''  
              });
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  // Modification code journal
  const handleOkMod = () => {
    setConfirmLoadingMod(true);

    const formData = new FormData();
    formData.append('libelle', codeJournaleMod.libelle);
    formData.append('abreviation', codeJournaleMod.abreviation);
    
    try {
      axios.post(api + `/api/modCodeJournal/${codeJournaleMod.id}`, formData)
          .then(res => {
              successMod();
              // Gérer la réponse en cas de succès
              // console.log('Ajout code journal réussie:', res.data);
              getAllCodeJournal()
              
          })
          .catch(err => {
              // Gérer les erreurs de la requête
              if (err.response) {
                  // Le serveur a répondu avec un code de statut différent de 2xx
                  console.error('Erreur réponse serveur:', err.response.data);
              } else if (err.request) {
                  // La requête a été faite, mais aucune réponse reçue
                  console.error('Aucune réponse reçue:', err.request);
              } else {
                  // Autre erreur lors de la configuration de la requête
                  console.error('Erreur lors de la requête:', err.message);
              }
          });
    } catch (error) {
        // Gérer les erreurs inattendues dans le bloc try
        console.error('Erreur inattendue:', error);
    }

    setTimeout(() => {
      setOpenModalMod(false);
      setConfirmLoadingMod(false);
    }, 2000);
  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleCancelMod = () => {
    // console.log('Clicked cancel button');
    setOpenModalMod(false);
  };

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
       {contextHolder}
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Code journal'}/>
        </div>
        <div className='pl-8 pr-7 py-4'>
          <div className='flex justify-between'>
            <div>
              
            </div>
            <div>
              <button onClick={showModal} className='px-4 py-1 font-semibold bg-primary text-white duration-300 border-primary rounded-lg border-2'>Ajouter</button>
            </div>
          </div>
          {/* Table */}
          <div className="overflow-x-auto mt-5 rounded-xl">
            {loading ? (
              // Spinner de chargement avec TailwindCSS
              <div className="flex justify-center items-center h-32">
                <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
              </div>
            ) : (
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr className="bg-gray-300 border-b">
                    <th className="py-2 px-4 text-left">Abréviation</th>
                    <th className="py-2 px-4 text-left">Libellé</th>
                    <th className="py-2 px-4 text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((journal) => (
                    <tr key={journal.id} className="border-b">
                      <td className="py-2 px-4">{journal.abreviation}</td>
                      <td className="py-2 px-4">{journal.libelle}</td>
                      <td className="py-2 px-4 space-x-4">
                        <EditOutlined
                          onClick={() => showModalMod(journal)}
                          style={{ fontSize: '20px', color: '#1890ff' }}
                          className="cursor-pointer"
                        />
                        <DeleteOutlined
                          onClick={() => showConfirm(journal)}
                          style={{ fontSize: '20px', color: 'red' }}
                          className="cursor-pointer"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

        </div>
      </div>

      {/* Modal d'ajout */}
      <Modal title="Ajout d'un code journal" open={open} onOk={handleOk} confirmLoading={confirmLoading} onCancel={handleCancel} footer={(
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={handleCancel} 
                  className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button
                  onClick={handleOk} 
                  className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  disabled={confirmLoading}
                >
                  {confirmLoading ? 'En cours...' : 'Enregistrer'}
                </button>
              </div>
            )}
          >
                <div className='space-y-2 mt-8 mb-4'>
                  <label className="font-semibold">Libellé</label>
                  <input
                    id="libelle"
                    name="libelle"
                    type="text"
                    onChange={handleInputChange}
                    required
                    value={codeJournale.libelle}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la libellé"
                  />
                </div>

                <div className='space-y-2 mt-3 mb-8'>
                  <label className="font-semibold">Abréviation</label>
                  <input
                    id="abreviation"
                    name="abreviation"
                    type="text"
                    onChange={handleInputChange}
                    required
                    value={codeJournale.abreviation}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'abréviation"
                  />
                </div>
      </Modal>

      {/* Modal de modification */}
      <Modal title="Modification d'un code journal" open={openModalMod} onOk={handleOkMod} confirmLoading={confirmLoadingMod} onCancel={handleCancelMod}footer={(
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={handleCancelMod} 
                  className="px-6 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button 
                  onClick={handleOkMod} 
                  className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  disabled={confirmLoadingMod}
                >
                  {confirmLoadingMod ? 'En cours...' : 'Enregistrer'}
                </button>
              </div>
            )}
          >
                <div className='space-y-2 mt-8 mb-4 hidden'>
                  <label className="font-semibold">Id</label>
                  <input
                    id="id"
                    name="id"
                    type="number"
                    required
                    value={codeJournaleMod.id}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  />
                </div>
                <div className='space-y-2 mt-8 mb-4'>
                  <label className="font-semibold">Libellé</label>
                  <input
                    id="libelle"
                    name="libelle"
                    type="text"
                    required
                    value={codeJournaleMod.libelle}
                    onChange={handleInputChangeMod}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez la libellé"
                  />
                </div>

                <div className='space-y-2 mt-3 mb-8'>
                  <label className="font-semibold">Abréviation</label>
                  <input
                    id="abreviation"
                    name="abreviation"
                    type="text"
                    // autoComplete="email"
                    required
                    value={codeJournaleMod.abreviation}
                    onChange={handleInputChangeMod}
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Entrez l'abréviation"
                  />
                </div>
      </Modal>
      
    </div>
  )
}

export default CodeJournale

