import React, { useEffect, useRef, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import {LeftCircleOutlined} from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import html2pdf from 'html2pdf.js';
import { api } from '../../../api'
import axios from 'axios'
import { Empty } from 'antd';

const Recapitulation = () => {
  const exportRef = useRef(); // Reference for the div to export
  const {mois, annee} = useParams()
  const user_id = sessionStorage.getItem('user_id');

  // Récuperer le nom du mois
  const moisNom = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  const moisIndex = parseInt(mois) - 1;
  const nomMois = moisNom[moisIndex] || "Mois inconnu";

  const [loading, setLoading] = useState(true);
  const [dataExist, setDataExist] = useState(false);

  const [debut60, setDebut60] = useState(0); const [debut61, setDebut61] = useState(0);
  const [debut62, setDebut62] = useState(0); const [debut63, setDebut63] = useState(0); const [debut64, setDebut64] = useState(0);
  const [debut65, setDebut65] = useState(0); const [debut66, setDebut66] = useState(0); const [debut67, setDebut67] = useState(0);

  const [debut31, setDebut31] = useState(0); const [debut35, setDebut35] = useState(0); const [debut37, setDebut37] = useState(0);
  const [debut70, setDebut70] = useState(0); const [debut76, setDebut76] = useState(0);

  const [debut20, setDebut20] = useState(0); const [debut21, setDebut21] = useState(0); const [debut23, setDebut23] = useState(0); const [debut27, setDebut27] = useState(0);
  const [debut10, setDebut10] = useState(0);

  const navigate = useNavigate()

  const revenir = () => {
    navigate('/admin/anneeFiscale/'+ annee + '/' + mois)
  }

  const handleExportPDF = () => {
    const element = exportRef.current;
    html2pdf()
      .from(element)
      .set({
        margin: 0,
        padding: 2,
        filename: 'recap_mensuel.pdf',
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'portrait' }
      })
      .save();
  };

  // Récuperer les informations dans le rapport financier
  const getDataRecapitulation = async () => {
    try {
      const response = await axios.get(api + `/api/getRecapitulation/${mois}/${annee}/${user_id}`);

      setLoading(false)

      if (response.data.existe)
      {

        const debut31Value = parseFloat(response.data.debut31); const debut35Value = parseFloat(response.data.debut35); const debut37Value = parseFloat(response.data.debut37);
        const debut70Value = parseFloat(response.data.debut70); const debut76Value = parseFloat(response.data.debut76);
        const debut20Value = parseFloat(response.data.debut20); const debut21Value = parseFloat(response.data.debut21); const debut23Value = parseFloat(response.data.debut23); const debut27Value = parseFloat(response.data.debut27);
        const debut10Value = parseFloat(response.data.debut10);

        setDebut31(debut31Value); setDebut35(debut35Value); setDebut37(debut37Value);
        setDebut70(debut70Value); setDebut76(debut76Value); 
        setDebut20(debut20Value); setDebut21(debut21Value); setDebut23(debut23Value); setDebut27(debut27Value);
        setDebut10(debut10Value);

      } else {
        setDataExist(true)
      }

      console.log(response.data);
      // setLoading(false);

    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };

  // Récuperer les informations dans le rapport financier
  const getDataRapportFinancier = async () => {
    try {
      const response = await axios.get(api + `/api/getRapportFinancier/${mois}/${annee}/${user_id}`);

      if (response.data.existe)
      {

        const debut60Value = parseFloat(response.data.debut60);
        const debut61Value = parseFloat(response.data.debut61); const debut62Value = parseFloat(response.data.debut62); const debut63Value = parseFloat(response.data.debut63);
        const debut64Value = parseFloat(response.data.debut64); const debut65Value = parseFloat(response.data.debut65); const debut66Value = parseFloat(response.data.debut66); 
        const debut67Value = parseFloat(response.data.debut67);

        setDebut60(debut60Value); setDebut61(debut61Value); setDebut62(debut62Value); setDebut63(debut63Value); setDebut64(debut64Value);
        setDebut65(debut65Value); setDebut66(debut66Value); setDebut67(debut67Value);

      } else {
        setDataExist(true)
      }

      // console.log(response.data);
      // setLoading(false);

    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };

  useEffect(() => {
    getDataRecapitulation()
    getDataRapportFinancier();
  }, []);

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Récapitulation'}/>
        </div>

        <div className='pr-7 py-6'>
          <div className='flex justify-between'>
            <div className='ml-8'>
              <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
            </div>
            <div>
                {
                      !dataExist && (
                        <>

                          <button
                            onClick={handleExportPDF}
                            className='bg-blue-500 text-white py-1 px-3 rounded'
                          >
                            Exporter en PDF
                          </button>

                        </>
                      )
                    }
            </div>
          </div>

          {
            loading ? (
              <>
                  <div className='justify-center items-center flex mt-28'>
                    <div className="w-10 h-10 justify-center items-center border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                  </div>
              </>
            ) : (
              <>
                  {
                  dataExist ? (
                    <div className='flex flex-col items-center justify-center h-full p-2 mt-44'>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span className='text-lg'>Vous n'avez pas encore des données. <br /></span>}
                        />
                    </div>
                  ) : (
                      <>

                            <div ref={exportRef} className='py-1 pl-8'>
                                        <div className='text-center mb-3 font-bold text-lg'>
                                          <span>RECAPITULATION</span>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 '>
                                          <div className='grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 gap-5'>
                                            <div className='flex flex-col font-semibold space-y-3'>
                                              <div className='space-x-7'><span>MOIS DE :</span> <span>{nomMois}</span></div>
                                            </div>
                                          </div>

                                          <div className='grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 gap-5 font-semibold'>
                                          <div className='flex flex-col font-semibold space-y-3'>
                                              <div className='space-x-7'><span>ANNEE FISCALE :</span><span>{annee}</span></div>
                                            </div>
                                          </div>
                                        </div>

                                        {/* tableau */}
                                        <div className=''>
                                          <table className='min-w-full border-collapse bg-white mb-5 mt-5'>
                                            <thead>
                                              <tr className='bg-primary'>
                                                <th className=' border-black w-3/4 px-4 py-2 text-lg text-white' colSpan='2'>Libellé</th>
                                                <th className='w-1/4 px-4 py-2 text-lg text-white'>Montant</th>
                                              </tr>
                                            </thead>

                                            <tbody>
                                              <tr className='border-b bg-blue-200'>
                                                <td colSpan='3' className='text-center border-blackpx-4 py-2 font-bold'>DEPENSE</td>
                                              </tr>
                                              <tr className='border-b'>
                                                <td className='w-1/8 text-center border-black'>60</td>
                                                <td className='border-black px-4 py-2'>Achats consomés</td>
                                                <td className='w-1/4 text-center'>{debut60.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b bg-gray-100'>
                                                <td className='w-1/8 text-center border-black'>61</td>
                                                <td className='border-black px-4 py-2'>Services exterieurs</td>
                                                <td className='w-1/4 text-center'>{debut61.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b'>
                                                <td className='w-1/8 text-center border-black'>62</td>
                                                <td className='border-black px-4 py-2'>Autres services exterieurs</td>
                                                <td className='w-1/4 text-center'>{debut62.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b bg-gray-100'>
                                                <td className='w-1/8 text-center border-black'>63</td>
                                                <td className='border-black px-4 py-2'>Impots, taxes et versemment assimilés</td>
                                                <td className='w-1/4 text-center'>{debut63.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b'>
                                                <td className='w-1/8 text-center border-black'>64</td>
                                                <td className='border-black px-4 py-2'>Charges personnels</td>
                                                <td className='w-1/4 text-center'>{debut64.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b bg-gray-100'>
                                                <td className='w-1/8 text-center border-black'>65</td>
                                                <td className='border-black px-4 py-2'>Autres charges des activités ordinaires</td>
                                                <td className='w-1/4 text-center'>{debut65.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b'>
                                                <td className='w-1/8 text-center border-black'>66</td>
                                                <td className='border-black px-4 py-2'>Charges financières</td>
                                                <td className='w-1/4 text-center'>{debut66.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b bg-gray-100'>
                                                <td className='w-1/8 text-center border-black'>67</td>
                                                <td className='border-black px-4 py-2'>Eléments extraordianires (charges)</td>
                                                <td className='w-1/4 text-center'>{debut67.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='bg-blue-200'>
                                                <td colSpan='3' className='text-center border-black px-4 py-2 font-bold'>STOCK</td>
                                              </tr>
                                              <tr className='border-b'>
                                                <td className='w-1/8 text-center border-black'>31</td>
                                                <td className='border-black px-4 py-2'>Matières premières et fournitures</td>
                                                <td className='w-1/4 text-center'>{debut31.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b bg-gray-100'>
                                                <td className='w-1/8 text-center border-black'>35</td>
                                                <td className='border-black px-4 py-2'>Stocks de produits</td>
                                                <td className='w-1/4 text-center'>{debut35.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b'>
                                                <td className='w-1/8 text-center border-black'>37</td>
                                                <td className='border-black px-4 py-2'>Stocks de marchandises</td>
                                                <td className='w-1/4 text-center'>{debut37.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='bg-blue-200'>
                                                <td colSpan='3' className='text-center border-black px-4 py-2 font-bold'>RECETTE</td>
                                              </tr>
                                              <tr className='border-b'>
                                                <td className='w-1/8 text-center border-black'>70</td>
                                                <td className='border-black px-4 py-2'>Ventes produits fabriqués, marchandises, prestations</td>
                                                <td className='w-1/4 text-center'>{debut70.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b bg-gray-100'>
                                                <td className='w-1/8 text-center border-black'>76</td>
                                                <td className='border-black px-4 py-2'>Produits financiers</td>
                                                <td className='w-1/4 text-center'>{debut76.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='bg-blue-200'>
                                                <td colSpan='3' className='text-center border-black px-4 py-2 font-bold'>IMMOBILISATION</td>
                                              </tr>
                                              <tr className='border-b'>
                                                <td className='w-1/8 text-center border-black'>20</td>
                                                <td className='border-black px-4 py-2'>Immobilisations incorporelles</td>
                                                <td className='w-1/4 text-center'>{debut20.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b bg-gray-100'>
                                                <td className='w-1/8 text-center border-black'>21</td>
                                                <td className='border-black px-4 py-2'>Immobilisations corporelles</td>
                                                <td className='w-1/4 text-center'>{debut21.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b'>
                                                <td className='w-1/8 text-center border-black'>23</td>
                                                <td className='border-black px-4 py-2'>Immobilisations en cours</td>
                                                <td className='w-1/4 text-center'>{debut23.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='border-b bg-gray-100'>
                                                <td className='w-1/8 text-center border-black'>27</td>
                                                <td className='border-black px-4 py-2'>Autres immobilisations financières</td>
                                                <td className='w-1/4 text-center'>{debut27.toLocaleString('fr-FR')}</td>
                                              </tr>
                                              <tr className='bg-blue-200'>
                                                <td colSpan='3' className='text-center border-black px-4 py-2 font-bold'>CAPITALE</td>
                                              </tr>
                                              <tr className='border-b'>
                                                <td className='w-1/8 text-center border-black'>10</td>
                                                <td className='border-black px-4 py-2'>Capital, réserves et assimilés</td>
                                                <td className='w-1/4 text-center'>{debut10.toLocaleString('fr-FR')}</td>
                                              </tr>

                                            </tbody>
                                          </table>
                                        </div>
                                      </div>

                      </>
                  )
                  }
              </>
            )
          }

          
        </div>
      </div>
      
    </div>
  )
}

export default Recapitulation

