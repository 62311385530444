import React, { useState } from 'react';
import img1 from '../../Assets/imgInscription.jpg'
import { useNavigate } from 'react-router-dom';

const Bienvenu = () => {
  const navigate = useNavigate()

  // Fonction pour passer à l'étape suivante
  const terminer = () => {
    // alert('Lien envoyer vers votre email')
    navigate('/connexion')
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {/* Image à gauche */}
      <div className="w-1/2 flex md:p-0 max-sm:hidden">
        <img src={img1} alt="Placeholder" className="w-full object-cover h-auto" />
      </div>

      {/* Formulaire à droite */}
      <div className="w-full md:w-1/2 md:p-8 max-sm:mt-10 max-sm:px-5 mx-auto flex flex-col items-center justify-center h-screen">
        <h2 className="t text-7xl font-bold mb-7 text-center md:text-left text-secondary">BIENVENUE</h2>
        <p className='text-center lg:px-20 md:mb-10 md:text-xl'>Votre inscription a été effectuée avec succès. Vous pouvez maintenant s’authentifier et effectuer votre demande d’abonnement.</p>

        <form className="w-full lg:px-20">
            
            <button
              type="button"
              onClick={terminer}
              className="bg-primary text-white px-4 py-2 font-semibold rounded w-full"
            >
              Terminer
            </button>
          </form>
          
        </div>
      


    </div>
  );
};

export default Bienvenu;
