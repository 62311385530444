import React, { useState } from "react";
import { Modal } from "antd";
import { api } from "../api";

const ModalImage = ({ imageUrl, visible, onClose }) => {
  return (
    <Modal
      title="Aperçu de l'image"
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      width={800} // Définit la largeur du modal
    >
      <div className="flex justify-center">
        <img
          src={api + '/storage/' + imageUrl}
          alt="Aperçu"
          style={{
            maxWidth: "100%",
            maxHeight: "80vh",
            borderRadius: "8px",
          }}
        />
      </div>
    </Modal>
  );
};

export default ModalImage;
