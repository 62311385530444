import React, { useEffect, useRef, useState } from 'react';
import html2pdf from 'html2pdf.js';
import SideBar from '../../../Components/Utilisateur/SideBar'
import Header from '../../../Components/Utilisateur/Header'
import { useNavigate, useParams } from 'react-router-dom';
import {LeftCircleOutlined} from '@ant-design/icons'
import { api } from '../../../api'
import axios from 'axios'
import { Empty } from 'antd';

const Bilan = () => {
  const exportRef = useRef(); // Reference for the div to export

  const {mois, annee} = useParams()
  const user_id = sessionStorage.getItem('admin_id');

  // Récuperer le nom du mois
  const moisNom = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  const moisIndex = parseInt(mois) - 1;
  const nomMois = moisNom[moisIndex] || "Mois inconnu";

  const [loading, setLoading] = useState(true);
  const [dataExist, setDataExist] = useState(false);
  const [totalImmobilisation, setTotalImmobilisation] = useState(0);
  const [totalCreance, setTotalCreance] = useState(0); const [totalDette, setTotalDette] = useState(0);
  const [totalBanque, setTotalBanque] = useState(0);  const [totalCaisse, setTotalCaisse] = useState(0);
  const [totalDebut10, setTotalDebut10] = useState(0);

  const [totalDebut70, setTotalDebut70] = useState(0);
  const [totalDebut60, setTotalDebut60] = useState(0); const [totalDebut61, setTotalDebut61] = useState(0); const [totalDebut62, setTotalDebut62] = useState(0); 
  const [totalDebut63, setTotalDebut63] = useState(0); const [totalDebut64, setTotalDebut64] = useState(0); const [totalDebut65, setTotalDebut65] = useState(0); const [totalDebut66, setTotalDebut66] = useState(0);

  const navigate = useNavigate()

  const revenir = () => {
    navigate('/utilisateur/anneeFiscale/'+ annee + '/' + mois)
  }

  const handleExportPDF = () => {
    const element = exportRef.current;
    html2pdf()
      .from(element)
      .set({
        margin: 1,
        filename: 'bilan_mensuel.pdf',
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'portrait' }
      })
      .save();
  };

  // Récuperer les informations dans le rapport financier
  const getDataBilanMensuel = async () => {
    try {
      const response = await axios.get(api + `/api/getBilan/${mois}/${annee}/${user_id}`);

      setLoading(false)

      if (response.data.existe)
      {
        const totalImmobilisationValue = parseFloat(response.data.totalImmobilisation);
        const totalCreanceValue = parseFloat(response.data.totalCreance); const totalDetteValue = parseFloat(response.data.totalDette);
        const totalBanqueValue = parseFloat(response.data.totalBanque); const totalCaisseValue = parseFloat(response.data.totalCaisse);
        const totalDebut10Value = parseFloat(response.data.totalDebut10);
        setTotalImmobilisation(totalImmobilisationValue);
        setTotalCreance(totalCreanceValue); setTotalDette(totalDetteValue);
        setTotalBanque(totalBanqueValue); setTotalCaisse(totalCaisseValue);
        setTotalDebut10(totalDebut10Value);
      } else {
        setDataExist(true)
      }

      console.log(response.data);
      // setLoading(false);

    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };

  // Récuperer les informations dans le rapport financier
  const getDataRapportFinancier = async () => {
    try {
      const response = await axios.get(api + `/api/getRapportFinancier/${mois}/${annee}/${user_id}`);

      setLoading(false)

      if (response.data.existe)
      {
        const totalDebut60Value = parseFloat(response.data.totalDebut60); const totalDebut61Value = parseFloat(response.data.totalDebut61);
        const totalDebut62Value = parseFloat(response.data.totalDebut62); const totalDebut63Value = parseFloat(response.data.totalDebut63); const totalDebut64Value = parseFloat(response.data.totalDebut64);
        const totalDebut65Value = parseFloat(response.data.totalDebut65); const totalDebut66Value = parseFloat(response.data.totalDebut66);

        const totalDebut70Value = parseFloat(response.data.totalDebut70);
        // Cumul des depenses
        setTotalDebut60(totalDebut60Value); setTotalDebut61(totalDebut61Value); setTotalDebut62(totalDebut62Value); setTotalDebut63(totalDebut63Value); setTotalDebut64(totalDebut64Value);
        setTotalDebut65(totalDebut65Value); setTotalDebut66(totalDebut66Value);

        setTotalDebut70(totalDebut70Value);

      } else {
        setDataExist(true)
      }

      // console.log(response.data);
      // setLoading(false);

    } catch (error) {
      console.error('Error fetching entity:', error);
    }
  };

  useEffect(() => {
    getDataBilanMensuel();
    getDataRapportFinancier()
  }, []);

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBar />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Bilan mensuel'} />
        </div>

        {/* Bouton pour exporter en PDF */}
        <div className='pr-7 py-6'>
         <div className='flex justify-between'>
          <div className='ml-8'>
            <span onClick={revenir}><LeftCircleOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/></span>
          </div>
          <div>
          {
                  !dataExist && (
                    <>

                      <button
                        onClick={handleExportPDF}
                        className='bg-blue-500 text-white py-1 px-3 rounded'
                      >
                        Exporter en PDF
                      </button>

                    </>
                  )
                }
          </div>
         </div>
        
        {
          loading ? (
            <>
                  <div className='justify-center items-center flex mt-28'>
                    <div className="w-10 h-10 justify-center items-center border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                  </div>
            </>
          ) : (
            <>
              {
                dataExist ? (
                  <div className='flex flex-col items-center justify-center h-full p-2 mt-44'>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span className='text-lg'>Vous n'avez pas encore des données. <br /></span>}
                      />
                  </div>
                ) : (
                  <>
                        {/* Contenu à exporter en PDF */}
                        <div ref={exportRef} className='pl-8 py-6'>
                          <div className='text-center mb-4 font-bold'>
                            <span>BILAN MENSUEL</span>
                          </div>
                          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-20'>
                            <div className='grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 gap-3'>
                              <div className='flex flex-col font-semibold space-y-1'>
                                <div className='space-x-7'><span>CLIENT :</span><span>EXPERT CONSEILS</span></div>
                                <div className='space-x-9'><span>CODE :</span><span>TNR-ACEP180006</span></div>
                                {/* <span className='pt-1'>SOLDE AU DEBUT DU MOIS :</span>
                                <span className='text-right pr-12'>Trésorerie :</span>
                                <span className='text-right pr-12'>Tiers :</span>
                                <span className='text-right pr-12 pt-1'>RECETTES DE LA PERIODE :</span>
                                <span className='text-right pr-12'>Ventes :</span>
                                <span className='text-right pr-12'>Revenu :</span>
                                <span className='text-right pr-12 pt-2'>DEPENSES DU MOIS :</span> */}
                              </div>

                            </div>

                            <div className='grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 gap-3 font-semibold'>
                            <div className='flex flex-col font-semibold space-y-1'>
                                <div className='space-x-9'><span>RAPPORT MOIS DE :</span><span>{nomMois}</span></div>
                                <div className='space-x-16'><span>ANNEE FISCALE :</span><span>{annee}</span></div>
                                {/* <span className='p pt-28'>CUMUL RECETTES :</span> */}
                              </div>

                              {/*  */}
                            </div>
                          </div>

                          {/* Tableau */}
                          <div className="overflow-x-auto mt-8">
                            <table className="min-w-full border-collapse bg-white">
                              <thead>
                                <tr className="border-b bg-gray-300">
                                  <th className="py-2 px-4 text-center border-r border-black" colSpan='2'>ACTIF</th>
                                  <th className="py-2 px-4 text-center" colSpan='2'>PASSIF</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className='border-black border-b w-1/4 py-2 px-2'>IMMOBILISATIONS</td>
                                  <td className='border-r border-black border-b w-1/4 py-2 text-right px-2'>{totalImmobilisation.toLocaleString('fr-FR')}</td>
                                  <td className='border-black border-b w-1/4 py-2 px-2'>CAPITAL / FONDS PROPRE</td>
                                  <td className='w-1/4 text-right border-b border-black py-2 px-2'>{totalDebut10.toLocaleString('fr-FR')}</td>
                                </tr>
                                <tr>
                                  <td className='border-black border-b py-2 w-1/4 px-2'>CREANCES</td>
                                  <td className='border-r border-black border-b py-2 w-1/4 text-right px-2'>{totalCreance.toLocaleString('fr-FR')}</td>
                                  <td className='border-black border-b py-2 w-1/4 px-2'>RESULTAT</td>
                                  <td className='w-1/4 text-right border-black border-b py-2 px-2'>{(totalDebut70 - (totalDebut60 + totalDebut61 + totalDebut62 + totalDebut63 + totalDebut64 + totalDebut65 + totalDebut66)).toLocaleString('fr-FR')}</td>
                                </tr>
                                <tr>
                                  <td className='border-black border-b w-1/4 py-2 px-2'>BANQUE</td>
                                  <td className='border-r border-black border-b w-1/4 py-2 text-right px-2'>{totalBanque.toLocaleString('fr-FR')}</td>
                                  <td className='border-black w-1/4 py-2 px-2'>DETTE</td>
                                  <td className='w-1/4 text-right py-2 px-2'>{totalDette.toLocaleString('fr-FR')}</td>
                                </tr>
                                <tr>
                                  <td className='border-black w-1/4 py-2 px-2'>CAISSE</td>
                                  <td className='border-r border-black w-1/4 py-2 text-right px-2'>{totalCaisse.toLocaleString('fr-FR')}</td>
                                  <td className='border-black w-1/4 py-2 px-2'></td>
                                  <td className='w-1/4 text-right py-2 px-2'></td>
                                </tr>
                                <tr className='font-bold'>
                                  <td className='border-t border-b border-black w-1/4 py-2 px-2'>TOTAL ACTIF</td>
                                  <td className='border-r border-b border-t border-black w-1/4 py-2 text-right px-2'>{(totalImmobilisation + totalCreance + totalBanque + totalCaisse).toLocaleString('fr-FR')}</td>
                                  <td className='border-t border-b border-black w-1/4 py-2 px-2'>TOTAL PASSIF</td>
                                  <td className='w-1/4 border-t border-b border-black text-right py-2 px-2'>{(totalDebut10 + (totalDebut70 - (totalDebut60 + totalDebut61 + totalDebut62 + totalDebut63 + totalDebut64 + totalDebut65 + totalDebut66)) + totalDette).toLocaleString('fr-FR')}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          {/* div plus bas */}
                          <div className='flex justify-between mt-5'>
                            <div></div>
                            <div className='flex space-x-16'>
                              <span>Vérification de calcul (égal à zero)</span>
                              <span>{((totalImmobilisation + totalCreance + totalBanque + totalCaisse) - (totalDebut10 + (totalDebut70 - (totalDebut60 + totalDebut61 + totalDebut62 + totalDebut63 + totalDebut64 + totalDebut65 + totalDebut66)) + totalDette)).toLocaleString('fr-FR')}</span>
                            </div>
                          </div>
                        </div>
                  </>
                )
              }
            </>
          )
        }
          
        </div>
      </div>
    </div>
  );
}

export default Bilan;
