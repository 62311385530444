import React from 'react'
import check from '../Assets/check.png'
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const Abonnement = () => {

  const navigate = useNavigate()

  const login = () => {
    navigate('/connexion')
  }

  return (
    <div className='md:px-24 p-4 mx-auto'>

      <h1 className='font-bold text-2xl mb-3 text-center md:mt-8 md:mb-6'>Abonnement</h1>
      
      <div className='flex flex-col md:flex-row justify-between items-center gap-10'>
          <div className='mx-auto'>
            {/* <img src={img5} alt="" className='lg:h-[386px]' /> */}
            <h1 className='font-semibold text-primary text-2xl md:text-5xl mb-4 md:mb-8 text-center'>Standard</h1>
            <p><span className='f font-bold text-3xl'>55 000 Ar </span><span className=' text-secondary text-sm'>/Mois</span></p>
            <p className='text-center'>ou</p>
            <p><span className='f font-bold text-3xl'>600 000 Ar </span><span className=' text-secondary text-sm'>/An</span></p>
          </div>

           <div className='md:w-3/5 border-2 md:rounded-2xl rounded-2xl p-5 md:p-8'>
            <ul>
              <li className='flex mb-4'>
                <img src={check} alt="" className='h-6 w-6 mr-3' />
                <p className='text-lg'>Accès à l’interface journal de saisie, plan comptable et année fiscale.</p>
              </li>
              <li className='flex mb-4'>
                <img src={check} alt="" className='h-6 w-6 mr-3' />
                <p className='text-lg'>Accès à tous statistique : situation des dépenses par mois, résultat de la période par rapport au CA, évolution CA-dépenses-résultats mensuel.</p>
              </li>
              <li className='flex mb-4'>
                <img src={check} alt="" className='h-6 w-6 mr-3' />
                <p className='text-lg'>Accès au rapport financier, bilan mensuel,balance générale.</p>
              </li>
              <li className='flex mb-4'>
                <img src={check} alt="" className='h-6 w-6 mr-3' />
                <p className='text-lg'>Exportation en fichier excel ou pdf des données.</p>
              </li>
              <li className='flex mb-4'>
                <img src={check} alt="" className='h-6 w-6 mr-3' />
                <p className='text-lg'>Accès à des recherches et filtrages.</p>
              </li>
              <li className='flex mb-4'>
                <img src={check} alt="" className='h-6 w-6 mr-3' />
                <p className='text-lg'> Accès à toutes fonctionnalités du plateforme.</p>
              </li>
             
              
            </ul>
            <div className='t text-center'>
              <Button type="primary" onClick={login} className="bg-primary hover:bg-blue-900 text-white
               font-bold py-3 px-20 w-full rounded">S'abonner</Button>
            </div>
            </div>
        </div>
        
    </div>
  )
}

export default Abonnement