import React, { useState } from 'react';
import { Button, Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import MenuList from './Admin/MenuList';
import Logo from '../Assets/Logo.png';
import { Header } from 'antd/es/layout/layout';

const SideBarKaonty = () => {
  const { Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout>
      {/* Sider fixé à gauche */}
      <Sider
        collapsed={collapsed}
        collapsible
        trigger={null}
        className="text-secondary"
        width={230}
        theme="light"
        style={{
          position: 'fixed',  // Fixer la barre latérale
          height: '100vh',    // Assurer la hauteur de la vue complète
          left: 0,            // Alignement à gauche
          top: 0,             // Alignement en haut
          zIndex: 1000        // Z-index si nécessaire
        }}
      >
        <div className="logo flex items-center justify-center p-2">
          <div className="logo-icon flex items-center justify-center rounded">
            <img src={Logo} alt="Logo" className="w-32" />
          </div>
        </div>
        <hr className="border-1" />
        <MenuList />
      </Sider>

      {/* Contenu principal, ajusté avec un margin-left pour éviter un chevauchement */}
      <Layout style={{ marginLeft: collapsed ? '80px' : '230px' }}>
        <Header style={{ padding: 1, background: '#F9F9FC' }}>
          <Button
            type="text"
            onClick={() => setCollapsed(!collapsed)}
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          />
        </Header>

        {/* Ajoutez ici le reste de votre contenu */}
        <div style={{ padding: 24 }}>Contenu principal</div>
      </Layout>
    </Layout>
  );
};

export default SideBarKaonty;
