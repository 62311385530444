import React, { useEffect, useState } from 'react'
import SideBarKaonty from '../../../Components/SideBarKaonty'
import Header from '../../../Components/Admin/Header'
import { Dropdown, Space } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import axios from 'axios';
import { api } from '../../../api';
import { useNavigate } from 'react-router-dom';

const Historique = () => {
  const user_id = sessionStorage.getItem('user_id');
  const navigate = useNavigate()

  // const data = [
  //   { id: 1, dateDebut: "15 Septembre 2024", dateFin: "15 Septembre 2025", type: "Standard", etat: 'En cours' },
  //   { id: 2, dateDebut: "15 Septembre 2023", dateFin: "15 Septembre 2024", type: "Standard", etat: 'Expiré' },
  //   { id: 3, dateDebut: "15 Septembre 2024", dateFin: "15 Septembre 2025", type: "Standard", etat: 'Expiré' },
  // ];

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);

  // Tous les demandes
  const getAllAbonnements = async () => {
    try {
      const response = await axios.get(api + `/api/allAbonnementUser/${user_id}`);
      setData(response.data);
      // setLoading(false);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Fonction pour rediriger vers la page des détails
  const  detailDemande = (id) => {
    navigate(`/admin/demande/${id}`);
  };

  const items = (id) => [
    {
      label: <p className='f font-semibold hover:text-primary' onClick={() => detailDemande(id)}>Voir demande</p>,
      key: '0',
    }
  ];

  useEffect(() => {
    getAllAbonnements();
  }, []);

  return (
    <div className='bg flex flex-row' style={{ height: '100vh' }}>
      <div>
        <SideBarKaonty />
      </div>
      <div className='bg w-full' style={{ background: '#F9F9FC' }}>
        <div>
          <Header titre={'Historique'}/>
        </div>

        <div className='pl-8 pr-7 py-6'>
          <div>
            <div>
              <span className='text-lg font-semibold'>Historique d'abonnement</span>
            </div>
          </div>

          {/* Tableau */}
          <div className="overflow-x-auto mt-5 rounded-xl">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
              {/* <tr>
                  <th className='py-2 px-4'><input type="date" className='border rounded-lg' /></th>
                  <th className='py-2 px-4'><input type="date" className='border rounded-lg' /></th>
                  <th className='py-2 px-4'><input type="text" className='border rounded-lg' /></th>
                  <th className='py-2 px-4'><input type="text" className='border rounded-lg px-2 py-1' /></th>
                  <th></th>
                </tr> */}
                <tr className="bg-gray-300 border-b">
                  <th className="py-2 px-4 text-left">Date début</th>
                  <th className="py-2 px-4 text-left">Date d'expiration</th>
                  <th className="py-2 px-4 text-left">Type</th>
                  <th className="py-2 px-4 text-left">Choix</th>
                  <th className="py-2 px-4 text-left">Prix (Ar)</th>
                  <th className="py-2 px-4 text-left">Etat</th>
                  <th className="py-2 px-4 text-left">Actions</th>
                </tr>
                
              </thead>
              <tbody>
               
                {data.map((abonnement) => (
                  <tr key={abonnement.id} className="border-b">
                    <td className="py-2 px-4">{new Date(abonnement.dateDebut).toLocaleDateString('fr-FR', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                              })}</td>
                    <td className="py-2 px-4">{new Date(abonnement.dateFin).toLocaleDateString('fr-FR', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                              })}</td>
                    <td className="py-2 px-4">{abonnement.type}</td>
                    <td className="py-2 px-4">{abonnement.choix}</td>
                    <td className="py-2 px-4">{parseInt(abonnement.prix).toLocaleString('fr-FR')}</td>
                    <td className={`py-2 px-4
                        ${abonnement.etat === 'EN COURS' ? 'text-blue-500' : ''} 
                        ${abonnement.etat === 'EXPIRE' ? 'text-red-500' : ''}`}>
                      <div className={`border inline-block px-2 rounded-full ${abonnement.etat === 'EN COURS' ? 'border-primary' : ''}
                        ${abonnement.etat === 'EXPIRE' ? 'border-red-500' : ''}`}>
                          {abonnement.etat}
                      </div>
                    </td>
                    <td className="py-2 px-4 space-x-4 text-center">
                    <Dropdown
                              menu={{
                                items: items(abonnement.demande_id),
                              }}
                              trigger={['click']}
                            >
                              <a
                                onClick={(e) => e.preventDefault()}
                                className="text-gray-800 font-medium flex items-center space-x-2 hover:text-blue-600"
                              >
                                <Space>
                                  
                                  <EllipsisOutlined style={{ fontSize: '25px', color: '#0071BD' }} className='c cursor-pointer'/>
                                </Space>
                              </a>
                            </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>
      </div>
      
    </div>
  )
}

export default Historique

